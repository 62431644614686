import { styled } from '@mui/material';

import type { Theme } from '../../../theming/theme';
import { preventForwardProps } from '../../../utilities/preventForwardProps';

const checkerboard = (theme: Theme) => ({
  backgroundColor: theme.palette.brand.linen50,
  backgroundImage: `linear-gradient(45deg, ${theme.palette.brand.linen100} 25%, transparent 25%, transparent 75%, ${theme.palette.brand.linen100} 75%, ${theme.palette.brand.linen100}), linear-gradient(45deg, ${theme.palette.brand.linen100} 25%, transparent 25%, transparent 75%, ${theme.palette.brand.linen100} 75%, ${theme.palette.brand.linen100})`,
  backgroundSize: `${theme.spacing(3)} ${theme.spacing(3)}`,
  backgroundPosition: `0 0, ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
});

const blurred = (url: string) => ({
  backgroundImage: `url(${url})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  filter: 'blur(20px)',
});

type ThumbnailBackgroundImageUrl = string;

export const ThumbnailContainer = styled(
  'div',
  preventForwardProps(['background'])
)<{ background?: ThumbnailBackgroundImageUrl | 'checkerboard' | null }>(({ theme, background }) => ({
  containerType: 'inline-size',

  aspectRatio: 16 / 9,
  borderRadius: theme.shape['borderRadius-l'],
  overflow: 'hidden',

  position: 'relative',

  ...(background && {
    '::before': {
      borderRadius: theme.shape['borderRadius-l'],
      content: '""',
      position: 'absolute',
      zIndex: 0,
      inset: 0,

      ...(background === 'checkerboard' ? checkerboard(theme) : blurred(background)),
    },
  }),

  '> [data-interactive]': {
    opacity: 0,
    transition: 'opacity 250ms ease',
  },

  '&:hover > [data-interactive], &:focus-within > [data-interactive]': {
    opacity: 1,
  },

  '& > *': {
    position: 'relative',
    zIndex: 0,
  },
}));
