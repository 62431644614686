import { type CSSProperties, type FC, useCallback } from 'react';
import { useMatch } from 'react-router-dom';

import { MainNavigation, Sidebar, Translatable, VisuallyHidden, styled } from '@cofenster/web-components';

import { useRequestRoleWithPermissions } from '../../../../../api/hooks/roleRequest/useRequestRoleWithPermissions';
import { useTeams } from '../../../../../api/hooks/team/useTeams';
import { FeatureFlagRestriction } from '../../../../../components/featureFlag/FeatureFlagRestriction';
import { useDialogs } from '../../../../../contexts/dialogs/useDialogs';
import { useProjectSpace } from '../../../../../contexts/projectSpace/useProjectSpace';
import {
  AccountPermissionRestriction,
  useAccountPermissionStatus,
} from '../../../../../contexts/user/AccountPermissionRestriction';
import { useUser } from '../../../../../contexts/user/useUser';
import { useGotoProjectFolder } from '../../../../../hooks/navigation/useGotoProjectFolder';
import { routes } from '../../../../../routes';
import { MainNavigationRouterLink } from '../MainNavigationRouterLink';

import { AddTeam } from './AddTeam';
import { UserProfileButton } from './UserProfileButton';

const BaseNavSection = styled(MainNavigation.NavSection)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const TeamNavSection = styled(MainNavigation.NavSection)(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));

// 1. Make the list scrollable when there is not enough available height to
//    display it in full.
const TeamNavList = styled(MainNavigation.NavList)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto', // 1
  borderRadius: theme.shape.borderRadius,

  '&:focus-visible': {
    outline: `2px solid ${theme.palette.brand.blue}`,
    outlineOffset: -2,
  },
}));

const PersonalSection: FC<{ isSelected: boolean }> = ({ isSelected }) => (
  <MainNavigation.NavSection>
    <MainNavigation.SectionHeader startIcon="LockIcon">i18n.mainNavigation.personal</MainNavigation.SectionHeader>
    <MainNavigationRouterLink
      to="projectFolder"
      params={{ folderId: 'personal' }}
      isSelected={isSelected}
      data-testid="personal-projects-link"
    >
      i18n.mainNavigation.personal.projects
    </MainNavigationRouterLink>
  </MainNavigation.NavSection>
);

export const UserNavigation: FC = () => {
  const { user } = useUser();
  const { teams, loading } = useTeams();
  const { openDialog } = useDialogs();
  const [currentProjectSpace] = useProjectSpace();
  const gotoProjectFolder = useGotoProjectFolder();
  const isProjectFolderRoute = !!useMatch({
    path: routes.projectFolder,
    end: false,
  });
  const isProjectRoute = !!useMatch({
    path: routes.project,
    end: false,
  });
  const isTeamSettingsRoute = !!useMatch({
    path: routes.teamSettings,
  });
  const isTeamMembersRoute = !!useMatch({
    path: routes.teamMembers,
  });
  const isAllowedToCreateTeams = useAccountPermissionStatus({ has: 'TeamCreate' }) === 'ALLOWED';
  const requestRoleWithPermissions = useRequestRoleWithPermissions();

  const onClickCreateTeam = useCallback(() => {
    if (isAllowedToCreateTeams) {
      openDialog('CreateTeamDialog', { gotoProjectFolder });
    } else {
      user &&
        openDialog('RequestPermissionDialog', {
          user,
          requestRole: () => requestRoleWithPermissions(['TeamCreate']),
        });
    }
  }, [gotoProjectFolder, isAllowedToCreateTeams, openDialog, requestRoleWithPermissions, user]);

  return (
    <Sidebar>
      <MainNavigation
        data-testid="sidebar-user"
        renderBottom={
          user &&
          (() => (
            <>
              <MainNavigation.Divider style={{ '--margin-top': 0 } as CSSProperties} />
              <PersonalSection
                isSelected={(isProjectFolderRoute || isProjectRoute) && 'private' === currentProjectSpace}
              />
              <MainNavigation.Divider style={{ '--margin-top': '24px' } as CSSProperties} />
              <UserProfileButton user={user} />
            </>
          ))
        }
      >
        <BaseNavSection>
          <MainNavigation.NavList>
            {/* biome-ignore lint/a11y: restore list semantics for WebKit */}
            <ul role="list">
              <li>
                <MainNavigation.SkipNavigationLink href="#content" data-testid="skip-link">
                  i18n.global.skipNavigation
                </MainNavigation.SkipNavigationLink>
              </li>

              <li>
                <MainNavigationRouterLink
                  to="home"
                  startIcon="HouseIcon"
                  data-testid="home-link"
                  matchPaths={[routes.home]}
                >
                  i18n.mainNavigation.home
                </MainNavigationRouterLink>
              </li>

              <li>
                <MainNavigationRouterLink
                  to="projectTemplates"
                  startIcon="LayoutIcon"
                  data-testid="project-templates-link"
                  matchPaths={[`${routes.projectTemplates}`]}
                >
                  i18n.mainNavigation.projectTemplates
                </MainNavigationRouterLink>
              </li>

              {__MEDIA_LIBRARY__ && (
                <li>
                  <MainNavigationRouterLink
                    to="brandingMediaLibrary"
                    startIcon="BrandingIcon"
                    data-testid="brand-kit-link"
                    matchPaths={[
                      routes.brandingMediaLibrary,
                      routes.brandingMediaLibraryItem,
                      routes.brandingThemes,
                      routes.brandingTheme,
                      routes.brandingMusics,
                      routes.brandingMusicCreate,
                      routes.brandingMusicUpdate,
                    ]}
                  >
                    i18n.mainNavigation.brandKit
                  </MainNavigationRouterLink>
                </li>
              )}
            </ul>
          </MainNavigation.NavList>
        </BaseNavSection>

        <TeamNavSection>
          <FeatureFlagRestriction
            has="MULTIPLE_TEAMS"
            fallback={
              <MainNavigation.SectionHeader startIcon="UsersIcon" id="team-header">
                i18n.mainNavigation.teams
              </MainNavigation.SectionHeader>
            }
          >
            <AccountPermissionRestriction
              has="TeamCreate"
              fallback={
                <MainNavigation.SectionHeader startIcon="UsersIcon" id="team-header">
                  i18n.mainNavigation.teams
                </MainNavigation.SectionHeader>
              }
            >
              <MainNavigation.SectionHeaderButton
                startIcon="UsersIcon"
                endIcon="PlusCircleIcon"
                onClick={() => openDialog('CreateTeamDialog', { gotoProjectFolder })}
                id="team-header"
                data-testid="add-team-button"
              >
                <span aria-hidden="true">
                  <Translatable>i18n.mainNavigation.teams</Translatable>
                </span>
                <VisuallyHidden>i18n.mainNavigation.createTeam</VisuallyHidden>
              </MainNavigation.SectionHeaderButton>
            </AccountPermissionRestriction>
          </FeatureFlagRestriction>

          {loading && !teams?.length ? null : teams?.length ? (
            <TeamNavList tabIndex={0} role="region" aria-labelledby="team-header">
              {/* biome-ignore lint/a11y: restore list semantics for WebKit */}
              <ul role="list">
                {teams.map((team) => (
                  <li key={team.id}>
                    <MainNavigationRouterLink
                      key={team.id}
                      to="projectFolder"
                      params={{ folderId: team.rootProjectFolder.id }}
                      isSelected={
                        (isProjectFolderRoute || isProjectRoute || isTeamSettingsRoute || isTeamMembersRoute) &&
                        team.id === currentProjectSpace
                      }
                      data-testid="team-projects-link"
                    >
                      {team.name}
                    </MainNavigationRouterLink>
                  </li>
                ))}
              </ul>
            </TeamNavList>
          ) : (
            <AddTeam onClick={onClickCreateTeam} />
          )}
        </TeamNavSection>
      </MainNavigation>
    </Sidebar>
  );
};
