import { useEffect, useMemo } from 'react';

import {
  type MediaLibraryItemsQueryResult,
  type MediaLibraryItemsQueryVariables,
  useMediaLibraryItemsQuery,
} from '../../generated';

export type MediaLibraryItem = NonNullable<
  NonNullable<NonNullable<MediaLibraryItemsQueryResult['data']>['mediaLibraryItems']>['items']
>[number];

export type MediaLibraryFilter = NonNullable<MediaLibraryItemsQueryVariables['filter']>;
export type MediaLibraryOrderBy = NonNullable<MediaLibraryFilter['orderBy']>;

const isAssetNotReady = (mediaLibraryItem: MediaLibraryItem) => {
  return (
    mediaLibraryItem.videoAsset?.status === 'Processing' ||
    mediaLibraryItem.videoAsset?.status === 'Waiting' ||
    mediaLibraryItem.imageAsset?.status === 'Waiting'
  );
};

export const useMediaLibraryItems = (filter: MediaLibraryFilter) => {
  const { loading, error, data, startPolling, stopPolling } = useMediaLibraryItemsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { filter },
  });

  const shouldPoll = data?.mediaLibraryItems?.items.some(isAssetNotReady);

  useEffect(() => {
    if (shouldPoll) {
      startPolling(5000);
      return () => stopPolling();
    }
  }, [shouldPoll, startPolling, stopPolling]);

  return useMemo(
    () => ({ paginatedMediaLibraryItems: data?.mediaLibraryItems, loading, error }),
    [data?.mediaLibraryItems, loading, error]
  );
};
