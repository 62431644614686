import { styled } from '@mui/material';

import { Typography } from '../../typography/Typography';

import type { FC, ReactNode } from 'react';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const TitleAndSubtitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  overflowX: 'hidden',
}));

const TruncatedTypography = styled(Typography)({
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const ThumbnailBar: FC<{
  title: ReactNode;
  subtitle?: ReactNode;
  menu?: ReactNode;
  'data-testid'?: string;
}> = ({ title, subtitle, menu, 'data-testid': dataTestId = 'thumbnail-bar' }) => {
  return (
    <Container data-testid={dataTestId}>
      <TitleAndSubtitleContainer>
        {typeof title === 'string' ? (
          <TruncatedTypography variant="h6" component="p" data-testid="thumbnail-title">
            {title}
          </TruncatedTypography>
        ) : (
          title
        )}
        {typeof subtitle === 'string' ? (
          <TruncatedTypography variant="s" component="p" data-testid="thumbnail-subtitle">
            {subtitle}
          </TruncatedTypography>
        ) : (
          subtitle
        )}
      </TitleAndSubtitleContainer>
      {menu}
    </Container>
  );
};
