import { GlobalDE } from '@cofenster/web-components';

import { SceneAssetLifecycleTranslationsDE } from '../../../../web-actor/src/components/capture/translations/DE';

import type { EN } from './EN';

export const DE: typeof EN = {
  ...GlobalDE,
  ...SceneAssetLifecycleTranslationsDE,
  'sceneTypes.color': 'Farbe',
  'sceneTypes.image': 'Bild',
  'sceneTypes.screenRecording': 'Bildschirmaufnahme',
  'sceneTypes.video': 'Video',
  'auth.button.backToLogin': 'Zurück zum Login',
  'auth.button.needHelp': 'Brauchst du Hilfe?',
  'branding.musics.addMusic': 'Musik hinzufügen',
  'branding.musics.create.headline': 'Neue Musik',
  'branding.musics.empty.cta': 'Lade eine Musik hoch',
  'branding.musics.empty.description': 'Du hast noch keine eigene Musik hochgeladen. Füge jetzt eine hinzu.',
  'branding.musics.empty.title': 'Keine eigene Musik',
  'branding.musics.headline': 'Musik',
  'branding.themes.createThemeCard.createTheme': 'Theme erstellen',
  'branding.themes.empty.description': 'Du hast noch kein eigenes Theme hochgeladen. Füge jetzt eins hinzu.',
  'branding.themes.empty.title': 'Keine Themes',
  'branding.themes.form.colorPrimary.label': 'Primärfarbe',
  'branding.themes.form.colorSecondary.label': 'Sekundärfarbe',
  'branding.themes.form.name.placeholder': 'Name',
  'branding.themes.formats.horizontal': 'Horizontal 16:9',
  'branding.themes.formats.socialMedia': 'Porträt 4:5',
  'branding.themes.formats.square': 'Quadratisch 1:1',
  'branding.themes.formats.vertical': 'Vertikal 9:16',
  'branding.themes.headline.colors': 'Farben',
  'branding.themes.headline.headline': 'Branding',
  'branding.themes.headline.intro': 'Intro',
  'branding.themes.headline.introAndOutro': 'Intro & Outro',
  'branding.themes.headline.logo': 'Logo',
  'branding.themes.headline.name': 'Name des Themes',
  'branding.themes.headline.outro': 'Outro',
  'branding.themes.themeCard.updatedAt': 'Bearbeitet {timeAgo}',
  'bulkUpload.dropzone.hint': 'Du kannst mehrere Dateien gleichzeitig hochladen.',
  'bulkUpload.dropzone.processing.hint': '{uploaded}/{total} Dateien hochgeladen',
  'bulkUpload.dropzone.processing.text': 'Deine Dateien laden hoch…',
  'bulkUpload.dropzone.text': 'Zieh deine Datei hierhin oder durchsuche deine Dateien',
  'CollectSceneAssetPage.instructions.title': 'Anweisungen',
  'CollectSceneAssetPage.title.image': 'Nimm ein Foto auf',
  'CollectSceneAssetPage.title.video': 'Nimm ein Video auf',
  'common.allFormats': 'Alle Formate',
  'common.archive': 'Archivieren',
  'common.branding': 'Branding',
  'common.confirm': 'Bestätigen',
  'common.copySharingLink.success': 'Videolink in die Zwischenablage kopiert',
  'common.copySharingLink': 'Sharing Link kopieren',
  'common.create': 'Erstellen',
  'common.createProject': 'Projekt erstellen',
  'common.deactivate': 'Deaktivieren',
  'common.duplicate': 'Duplizieren',
  'common.edit': 'Bearbeiten',
  'common.email': 'E-Mail',
  'common.exportVideo.error.general': 'Dein Video kann nicht exportiert werden, da es keine Szenen enthält oder einige der hochgeladenen Dateien noch optimiert werden',
  'common.exportVideo': 'Exportieren',
  'common.firstname': 'Vorname',
  'common.gridView': 'Kachelansicht',
  'common.help': 'Hilfe',
  'common.horizontal': 'Horizontal',
  'common.invite': 'Einladen',
  'common.language': 'Sprache',
  'common.lastname': 'Nachname',
  'common.listView': 'Listenansicht',
  'common.logoUpload.hint': '({fileFormat}, max. 2MB)',
  'common.logoUpload.text': 'Lege dein Logo hier ab oder durchsuche deinen Computer',
  'common.mediaLibrary': 'Medienbibliothek',
  'common.move': 'Verschieben',
  'common.name': 'Name',
  'common.orderBy': 'Sortieren nach',
  'common.playItem': '„{name}” abspielen',
  'common.project': 'Projekt',
  'common.projects': 'Projekte',
  'common.reactivate': 'Reaktivieren',
  'common.remove': 'Entfernen',
  'common.rename': 'Umbenennen',
  'common.resendInvite': 'Erneut einladen',
  'common.socialMedia': 'Porträt',
  'common.square': 'Quadratisch',
  'common.status': 'Status',
  'common.unarchive': 'Reaktivieren',
  'common.upload.audio.state.error': 'Ups. Bei der Verarbeitung deines Video ist ein Fehler aufgetreten. Wahrscheinlich ist dein Video fehlerhaft.',
  'common.upload.audio.state.processing': 'Einen Moment, dein Audio ist gleich fertig!',
  'common.upload.audio.state.uploading': 'Dein Audio wird hochgeladen',
  'common.upload.audio.text': 'Lege deine Audiodatei hier ab oder durchsuche deinen Computer',
  'common.upload.video.text': 'Lege dein Video hier ab oder durchsuche deinen Computer',
  'common.uploading': 'Hochladen',
  'common.user': 'Kunde',
  'common.userDetails': 'Benutzer info',
  'common.userRole': 'Benutzer-Rolle',
  'common.vertical': 'Vertikal',
  'common.videoFormat': 'Format',
  'common.videoFormatLong': 'Videoformat',
  'common.viewConsents': 'Einverständniserklärungen',
  'consentDatabase.actions.delete': 'Löschen',
  'consentDatabase.agreements': 'Einverständnisse',
  'consentDatabase.empty.cta': 'Zu den Einverständnis Einstellungen',
  'consentDatabase.empty.description': 'Du kannst von allen Teilnehmer Einverständnis zur Videonutzung einholen',
  'consentDatabase.empty.title': 'Noch keine Einverständniserklärung registriert',
  'consentDatabase.noAccess.description': 'Du benötigst die Berechtigung ConsentRead, um auf diese Seite zugreifen zu können',
  'consentDatabase.noAccess.title': 'Kein Zugang',
  'consentDatabase.noResults.description': 'Es wurde kein Einverständnis zu diesen Suchkriterien gefunden',
  'consentDatabase.noResults.title': 'Kein Einverständnis gefunden',
  'consentDatabase.project.consent': 'Einverständnisse',
  'consentDatabase.project.contributor': 'Teilnehmer',
  'consentDatabase.project.signedAt': 'Unterschrieben am',
  'consentDatabase.projectName': 'Projekt',
  'consentDatabase.search': 'Suche nach Teilnehmer und Projekten…',
  'contributionRequest.sharingList.duplicate': '{name} existiert bereits',
  'contributionRequest.type': 'Art',
  'contributionRequest.type.image': 'Bild',
  'contributionRequest.type.screenRecording': 'Bildschirmaufnahme',
  'contributionRequest.type.video': 'Video',
  'contributionRequest.typeSelect.label': 'Art von Inhalt',
  'deactivatedUser.headline': 'Dein Konto scheint deaktiviert zu sein.',
  'deactivatedUser.requestAccess': 'Zugang anfordern',
  'deactivatedUser.subline': 'Falls du cofenster weiterhin nutzen möchtest, fordere einen neuen Zugang an.',
  'deactivatedUser.title': 'Zugriff deaktiviert',
  'dialogs.addTeamMembersDialog.description': 'Füge Mitglieder von {accountName} zu deinem privaten Team hinzu.',
  'dialogs.addTeamMembersDialog.headline': 'Lade Leute zu {teamName} ein.',
  'dialogs.addTeamMembersDialog.label': 'Neue Mitglieder',
  'dialogs.addTeamMembersDialog.placeholder': 'Name oder E-Mail eingeben…',
  'dialogs.changeAnimationStyleDialog.cancel': 'Abbrechen',
  'dialogs.changeAnimationStyleDialog.confirm': 'Ja, ich bin sicher',
  'dialogs.changeAnimationStyleDialog.descriptionLine1': 'Dadurch {count, plural, one {kann dein vorhandenes Textelement} other {können # vorhandene Textelemente}} entfernt werden.',
  'dialogs.changeAnimationStyleDialog.descriptionLine2': ' Bist du dir sicher?',
  'dialogs.changeAnimationStyleDialog.headline': 'Animationsstil ändern',
  'dialogs.ConfirmArchiveDialog.button': 'Archivieren',
  'dialogs.ConfirmArchiveDialog.headline': 'Projekt archivieren',
  'dialogs.ConfirmArchiveDialog.text': 'Dieses Projekt ist derzeit öffentlich freigegeben. Durch das Archivieren wird die Veröffentlichung aufgehoben und es ist außerhalb deiner Organisation nicht mehr verfügbar.',
  'dialogs.ConfirmBulkArchiveDialog.button': 'Bestätigen',
  'dialogs.ConfirmBulkArchiveDialog.headline': 'Projekte archivieren',
  'dialogs.ConfirmBulkArchiveDialog.text': 'Dadurch wird die Veröffentlichung der öffentlich freigegebenen Projekte (falls vorhanden) aufgehoben und sie sind außerhalb deiner Organisation nicht mehr verfügbar.',
  'dialogs.ContributionRequestListDialog.contributionRequestListSelect.default': 'Alle Anfragen für Beiträge',
  'dialogs.ContributionRequestListDialog.contributionRequestListSelect.label': 'Ausgewählte Sharing-Liste',
  'dialogs.ContributionRequestListDialog.emailInput.label': 'Email eingeben, Eingabetaste drücken um weitere hinzuzufügen',
  'dialogs.ContributionRequestListDialog.emailInput.validation.duplicateEmail': 'Diese Email ist bereits hinzugefügt',
  'dialogs.ContributionRequestListDialog.emailInput.validation.invalidEmail': 'Diese Email Adresse existiert nicht',
  'dialogs.ContributionRequestListDialog.emailInput.validation.min': 'Du musst mindestens einen E-Mail-Empfänger angeben',
  'dialogs.ContributionRequestListDialog.emailMessageInput.label': 'Nachricht(optional)',
  'dialogs.ContributionRequestListDialog.headline': 'Beiträge anfragen',
  'dialogs.createProjectFolderDialog.button': 'Ordner hinzufügen',
  'dialogs.createProjectFolderDialog.description': 'Bitte wähle einen Namen für den neuen Ordner:',
  'dialogs.createProjectFolderDialog.headline': 'Ordner hinzufügen',
  'dialogs.CreateProjectFolderDialog.label': 'Ordnername',
  'dialogs.createScenesDialog.addScene': 'Szene hinzufügen',
  'dialogs.createScenesDialog.uploading': 'Lade existierende Dateien hoch',
  'dialogs.createTeamDialog.description': 'Wähle einen Namen, der dem Zweck dieses Teams entspricht, und beginne mit der Zusammenarbeit.',
  'dialogs.createTeamDialog.headline': 'Team hinzufügen',
  'dialogs.copyPasteDeniedDialog.content': 'Wir benötigen Zugriff auf deine Zwischenablage um Szenen zu kopieren und einzufügen. Bitte gib uns den nötigen Zugriff auf deine Zwischenablage über die Browsereinstellungen.',
  'dialogs.copyPasteDeniedDialog.title': 'Gib uns Zugriff auf deine Zwischenablage',
  'dialogs.deactivateUserDialog.button': 'Benutzer deaktivieren',
  'dialogs.deactivateUserDialog.headline': 'Bist du sicher, dass du diesen Benutzer deaktivieren möchtest?',
  'dialogs.deactivateUserDialog.text.paragraph1': 'Der Benutzer wird keinen Zugang zu cofenster mehr haben.',
  'dialogs.deactivateUserDialog.text.paragraph2': 'Alle Teams, die ihm gehörten, werden an dich übertragen. Du kannst die Inhalte der Teams überprüfen und sie bei Bedarf neu zuordnen oder löschen.',
  'dialogs.DeleteAssetDialog.button': 'Löschen',
  'dialogs.DeleteAssetDialog.headline': 'Anlage löschen',
  'dialogs.DeleteAssetDialog.text': 'Möchtest du diese Anlage wirklich löschen? Dadurch werden auch bereits aufgenommene Aufnahmen gelöscht.',
  'dialogs.DeleteAssetDialog.multiple.headline': 'Anlage {count, plural, one {löschen} other {löschen}}',
  'dialogs.DeleteAssetDialog.multiple.text': 'Möchtest du {count, plural, one {diese Anlage} other {diese Anlagen}} wirklich löschen? Dadurch werden auch bereits aufgenommene Aufnahmen gelöscht.',
  'dialogs.deleteConsent.description': 'Bist du sicher, dass du die Einverständniserklärung löschen möchtest?',
  'dialogs.deleteConsent.title': 'Einverständniserklärung löschen',
  'dialogs.deleteContributionRequestDialog.deleteButton': 'Anfrage löschen',
  'dialogs.deleteContributionRequestDialog.paragraph1': 'Bist du sicher, dass du diese Anfrage für Beiträge löschen möchtest?',
  'dialogs.deleteContributionRequestDialog.paragraph2': 'Alle bereits gesammelten Beiträge, die zum Video hinzugefügt wurden, bleiben erhalten.',
  'dialogs.deleteContributionRequestDialog.paragraph3': 'Alle nicht zum Video hinzugefügten Beiträge werden gelöscht.',
  'dialogs.deleteContributionRequestDialog.title': 'Anfrage für Beiträge löschen?',
  'dialogs.DeleteMusicDialog.headline': 'Musik löschen',
  'dialogs.DeleteMusicDialog.text': 'Bist du sicher, dass du die Musik mit dem Namen „{name}“ löschen willst? Projekte, die diese Musik verwenden, werden nicht automatisch neu gerendert, und du musst die Musik manuell aktualisieren.',
  'dialogs.deleteProjectDialog.headline': 'Projekt löschen',
  'dialogs.deleteProjectDialog.description': 'Möchtest du „{name}“ wirklich dauerhaft löschen? Diese Aktion kann nicht rückgängig gemacht werden.',
  'dialogs.deleteProjectDialog.description.published': 'Der öffentlich freigegebene Link ist dann nicht mehr zugänglich.',
  'dialogs.deleteProjectDialog.button': 'Löschen',
  'dialogs.deleteProjectFolderDialog.button': 'Ordner löschen',
  'dialogs.deleteProjectFolderDialog.description': 'Willst du diesen Ordner und alle enthaltenen Projekte wirklich dauerhaft löschen?',
  'dialogs.deleteProjectFolderDialog.headline': 'Ordner löschen',
  'dialogs.deleteProjectsDialog.button': '{count, plural, =0 {Projekt} one {Projekt} other {Projekte}} löschen',
  'dialogs.deleteProjectsDialog.description.all': 'Willst du {count, plural, =0 {Veröffentlichte # Projekte} one {Veröffentlichtes Projekt} other {Veröffentlichte # Projekte}} wirklich dauerhaft löschen? Die freigegebenen Links werden nicht mehr zugänglich sein.',
  'dialogs.deleteProjectsDialog.description.none': 'Willst du {count, plural, =0 {diese # Projekte} one {dieses Projekt} other {diese # Projekte}} wirklich dauerhaft löschen?',
  'dialogs.deleteProjectsDialog.description.some': 'Willst du {count, plural, =0 {diese # Projekte} one {dieses Projekt} other {diese # Projekte}} wirklich dauerhaft löschen? Die freigegebenen Links werden nicht mehr zugänglich sein.',
  'dialogs.deleteProjectsDialog.headline.all': '{count, plural, =0 {Veröffentlichte Projekte} one {Veröffentlichtes Projekt} other {Veröffentlichte Projekte}} löschen',
  'dialogs.deleteProjectsDialog.headline.none': '{count, plural, =0 {Projekt} one {Projekt} other {Projekte}} löschen',
  'dialogs.deleteProjectsDialog.headline.some': '{count, plural, =0 {Veröffentlichte Projekte} one {Veröffentlichtes Projekt} other {Veröffentlichte Projekte}} löschen',
  'dialogs.DeleteSceneDialog.button': 'Löschen',
  'dialogs.DeleteSceneDialog.headline': '{count, plural, =0 {# Szene} one {# Szene} other {# Szene}} löschen',
  'dialogs.DeleteSceneDialog.text': 'Möchtest du diese {count, plural, =0 {# Szene} one {# Szene} other {# Szene}} wirklich löschen? Dadurch werden auch bereits aufgenommene Aufnahmen gelöscht.',
  'dialogs.deleteSceneOverlayDialog.headline': 'Ebene löschen',
  'dialogs.deleteSceneOverlayDialog.text': 'Bist du sicher, dass du diese Ebene löschen möchtest?',
  'dialogs.DeleteSceneTextDialog.button': 'Löschen',
  'dialogs.DeleteSceneTextDialog.headline': 'Text löschen',
  'dialogs.DeleteSceneTextDialog.text': 'Möchtest du diesen Text wirklich löschen?',
  'dialogs.DeleteSharingListDialog.text': 'Bist du sicher, dass du „{name}“ löschen möchtest? Dies wirkt sich auf alle Anfragen aus, die dieser Liste zugeordnet sind.',
  'dialogs.DeleteSharingListDialog.title': 'Eigene Liste löschen',
  'dialogs.DeleteTeamDialog.headline': 'Team löschen',
  'dialogs.DeleteTeamDialog.teamName.error.equal': 'Gebe zur Bestätigung den Teamnamen korrekt ein',
  'dialogs.DeleteTeamDialog.teamName.error.required': 'Gebe zur Bestätigung den Teamnamen ein',
  'dialogs.DeleteTeamDialog.text.paragraph1': 'Löscht alle Videos in „{teamName}“ für immer.',
  'dialogs.DeleteTeamDialog.text.paragraph2': 'Gib den Teamnamen ein, um dies zu bestätigen.',
  'dialogs.deleteTheme.button': 'Löschen',
  'dialogs.deleteTheme.headline': 'Theme löschen',
  'dialogs.deleteTheme.text': 'Willst du dieses Theme wirklich löschen?',
  'dialogs.DeleteUserDialog.button': 'Entfernen',
  'dialogs.DeleteUserDialog.headline': 'Diese Person aus dem Unternehmen entfernen?',
  'dialogs.DeleteUserDialog.text.paragraph1': '{userName} wird keinen Zugriff mehr auf Teams und Projekte in cofenster haben.',
  'dialogs.DeleteUserDialog.text.paragraph2': 'Alle Teams, die ihm gehörten, werden an dich übertragen. Du kannst die Inhalte der Teams überprüfen und sie bei Bedarf neu zuordnen oder löschen.',
  'dialogs.DeleteUserDialog.text.paragraph3': 'Diese Aktion kann nicht rückgängig gemacht werden. Gib zur Bestätigung den Namen der Person unten ein.',
  'dialogs.DeleteUserDialog.userName.error.equal': 'Bitte gib den Namen der Person korrekt ein',
  'dialogs.DeleteUserDialog.userName.error.required': 'Bitte gib den Namen der Person ein',
  'dialogs.DeleteUserDialog.userName.label': 'Name der Person',
  'dialogs.duplicateProjectDialog.button': 'Projekt duplizieren',
  'dialogs.duplicateProjectDialog.defaultName': 'Kopie von {name}',
  'dialogs.duplicateProjectDialog.description': 'Wie möchtest du dein Projekt duplizieren?',
  'dialogs.duplicateProjectDialog.duplicateAs': 'Duplizieren als',
  'dialogs.duplicateProjectDialog.headline': 'Projekt duplizieren…',
  'dialogs.duplicateProjectDialog.videoFormat': 'Videoformat',
  'dialogs.moveProjectFolderDialog.description': 'Wähle das Team in welches du den Ordner verschieben möchtest,',
  'dialogs.moveProjectFolderDialog.headline': 'Ordner verschieben',
  'dialogs.moveProjectFolderDialog.moveFolder': 'Ordner verschieben',
  'dialogs.moveProjectFolderDialog.moveOutOfPrivateSpace': 'Jeder in {team} wird Zugang erhalten. Du kannst diesen Ordner nicht mehr zurückverschieben.',
  'dialogs.moveProjectFolderDialog.noTeams': 'Es gibt kein Team, in das du diesen Ordner verschieben kannst.',
  'dialogs.moveProjectFolderDialog.teamSelect.label': 'Zielteam',
  'dialogs.moveProjectsDialog.button': 'Verschieben',
  'dialogs.moveProjectsDialog.description': 'Bitte wähle den Ordner aus, in den du {count, plural, =0 {deine # Projekte} one {dein Projekt} other {deine # Projekte}} verschieben möchtest:',
  'dialogs.moveProjectsDialog.folderSelect.label': 'Zielordner',
  'dialogs.moveProjectsDialog.headline': 'Verschiebe {count, plural, =0 {# Projekte} one {Projekt} other {# Projekte}} nach…',
  'dialogs.moveProjectsDialog.moveOutOfPrivateSpace': 'Jeder in {team} wird Zugang erhalten. Du kannst dieses Projekt nicht mehr zurückverschieben.',
  'dialogs.moveProjectsDialog.noFolders': 'Es gibt keinen Ordner, in den du {count, plural, =0 {diese Projekte} one {dieses Projekt} other {diese Projekte}} verschieben kannst.',
  'dialogs.moveProjectsDialog.teamRootFolder': 'Direkt in das Team',
  'dialogs.moveProjectsDialog.teamSelect.label': 'Zielteam',
  'dialogs.MoveScenesToProjectDialog.decisionStep.newProjectDescription': 'Erstelle ein neues Projekt mit den ausgewählten Szenen',
  'dialogs.MoveScenesToProjectDialog.decisionStep.newProjectInputLabel': 'Projektname',
  'dialogs.MoveScenesToProjectDialog.decisionStep.projectDescription.creator': 'Von {creator}',
  'dialogs.MoveScenesToProjectDialog.decisionStep.projectDescription.lastEdited': 'Editiert {lastEdited}',
  'dialogs.MoveScenesToProjectDialog.decisionStep.searchProjectsInputPlaceholder': 'Suche nach Projekten',
  'dialogs.MoveScenesToProjectDialog.decisionStep.targetProjectRadioLegend': 'Wählen Sie ein Zielprojekt',
  'dialogs.MoveScenesToProjectDialog.destination.existingProject': 'Zu existierendem Projekt hinzufügen',
  'dialogs.MoveScenesToProjectDialog.destination.legend': 'Wählen Sie ein Zielprojekt (neu oder bestehend)',
  'dialogs.MoveScenesToProjectDialog.destination.newProject': 'Neues Projekt erstellen',
  'dialogs.MoveScenesToProjectDialog.keepSourceScenes': 'Behalte eine Kopie der ausgewählten {count, plural, =0 {Szenen} one {Szene} other {Szenen}} in diesem Projekt',
  'dialogs.MoveScenesToProjectDialog.search.error.description': 'Bei der Suche ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  'dialogs.MoveScenesToProjectDialog.search.error.title': 'Ein Fehler ist aufgetreten',
  'dialogs.MoveScenesToProjectDialog.search.noResults.description': 'Keine Projekte für diese Suchkriterien gefunden.',
  'dialogs.MoveScenesToProjectDialog.search.noResults.title': 'Keine Ergebnisse',
  'dialogs.MoveScenesToProjectDialog.search.results': '{count, plural, one {# Ergebnis} other {# Ergebnisse}}',
  'dialogs.MoveScenesToProjectDialog.title': 'In Projekt verschieben',
  'dialogs.reactivateUserDialog.button': 'Benutzer reaktivieren',
  'dialogs.reactivateUserDialog.description': 'Der Nutzer wird wieder Zugang zu cofenster haben.',
  'dialogs.reactivateUserDialog.headline': 'Bist du sicher, dass du diesen Nutzer wieder reaktivieren möchtest?',
  'dialogs.recordYourself.button': 'Fortfahren',
  'dialogs.recordYourself.headline': 'Aufnahme starten',
  'dialogs.recordYourself.videoFormat': 'Video Format',
  'dialogs.renameProjectDialog.button': 'Umbenennen',
  'dialogs.renameProjectDialog.description': 'Bitte gib einen neuen Projektnamen ein:',
  'dialogs.renameProjectDialog.headline': 'Projekt umbennenen',
  'dialogs.renameProjectDialog.projectTemplate.button': 'Umbenennen',
  'dialogs.renameProjectDialog.projectTemplate.description': 'Bitte gib den neuen Namen deiner Vorlage an:\n',
  'dialogs.renameProjectDialog.projectTemplate.headline': 'Vorlage umbennenen',
  'dialogs.renameProjectFolderDialog.button': 'Umbenennen',
  'dialogs.renameProjectFolderDialog.description': 'Bitte gib einen neuen Namen für diesen Ordner ein:',
  'dialogs.renameProjectFolderDialog.headline': 'Ordner umbenennen',
  'dialogs.requestPermission.learnMore': 'Mehr erfahren',
  'dialogs.requestPermission.message.headline.account': 'Du bist gerade ein {roleName}.',
  'dialogs.requestPermission.message.headline.team': 'Du bist gerade ein {roleName} in diesem Team.',
  'dialogs.requestPermission.message.subline': 'Wenn du mehr mit cofenster machen möchtest, bitte einen Administrator deine Rolle anzupassen.',
  'dialogs.requestPermission.requestPermissionButton': 'Rechte anfragen',
  'dialogs.requestPermission.title': 'Rechte anfragen',
  'dialogs.sceneSubtitles.addLanguage.description': 'Wähle eine Sprache die zu deinen Untertiteln hinzufgefügt wird',
  'dialogs.sceneSubtitles.addLanguage.headline': 'Untertitelsprache hinzufügen',
  'dialogs.sceneSubtitles.addLanguage.submitButton': 'Sprache hinzufügen',
  'dialogs.SendUserInviteEmailDialog.headline': 'Einladung erneut senden?',
  'dialogs.SendUserInviteEmailDialog.text': 'Dadurch wird eine neue Einladung verschickt, die 5 Tage lang gültig ist. Frühere Einladungslinks sind dann nicht mehr gültig.',
  'dialogs.SessionExpiredDialog.button': 'Anmelden',
  'dialogs.SessionExpiredDialog.description': 'Deine Sitzung scheint abgelaufen zu sein und du musst dich erneut anmelden. Klicke auf die Schaltfläche unten, um zur Anmeldeseite weitergeleitet zu werden.',
  'dialogs.SessionExpiredDialog.headline': 'Sitzung abgelaufen',
  'dialogs.StockLibraryDialog.pexels': 'Von <link>Pexels</link> bereitgestellte Ergebnisse',
  'dialogs.StockLibraryDialog.save.image': 'Bild hinzufügen',
  'dialogs.StockLibraryDialog.save.video': 'Video hinzufügen',
  'dialogs.StockLibraryDialog.search.error.description': 'Bei der Suche ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  'dialogs.StockLibraryDialog.search.error.title': 'Ein Fehler ist aufgetreten',
  'dialogs.StockLibraryDialog.search.label': 'Stock Bibliothek durchsuchen',
  'dialogs.StockLibraryDialog.search.noResults.description.image': 'Wir konnten kein Bild finden, das deinen Suchkriterien entspricht.',
  'dialogs.StockLibraryDialog.search.noResults.description.video': 'Wir konnten kein Video finden, das deinen Suchkriterien entspricht.',
  'dialogs.StockLibraryDialog.search.noResults.title': 'Keine Ergebnisse',
  'dialogs.StockLibraryDialog.title': 'Stock Bibliothek',
  'dialogs.subtitles.deleteNode.button': 'Löschen',
  'dialogs.subtitles.deleteNode.content': 'Mit dem Löschen dieser Untertitel Zeile wird all dein Text gelöscht. Bist du sicher, dass du deinen Text löschen möchtest?',
  'dialogs.subtitles.deleteNode.header': 'Untertitel Zeile löschen',
  'dialogs.team.leave.confirm.headline': '{team} verlassen?',
  'dialogs.team.leave.confirm.subline': 'Du verlierst den Zugang zu allen Ordnern und Projekten in diesem Team. Um wieder beizutreten musst du eingeladen werden.',
  'dialogs.team.owner.confirm': 'Team übertragen',
  'dialogs.team.owner.description': 'Mache {newOwner} zum Eigentümer des Teams. Diese Aktion kann nicht rückgängig gemacht werden. Du wirst zum regulären Mitglied des Teams.',
  'dialogs.team.owner.title': 'Team übertragen',
  'forgot.error.expired': 'abgelaufen',
  'forgot.error.invalid': 'ungültig',
  'forgot.error': 'Dein Link ist {error}. Gib die E-Mail-Adresse ein, die du für cofenster verwendest.',
  'forgot.form.submit': 'Passwort zurücksetzen',
  'forgot.headline': 'Passwort zurücksetzen.',
  'forgot.subline': 'Keine Sorge, passiert den Besten von uns! Gib die E-Mail Adresse ein, die du für cofenster verwendest.',
  'forgot.title': 'Passwort zurücksetzen',
  'form.error.color.required': 'Bitte wähle eine Farbe aus',
  'form.error.email.format': 'Bitte gib eine gültige E-Mail Adresse ein',
  'form.error.email.required': 'Bitte gib eine E-Mail Adresse ein',
  'form.error.firstname.required': 'Bitte gib den Vornamen ein',
  'form.error.generic.lineCount': 'Dieses Feld darf max. {lineCount} Zeilen beinhalten',
  'form.error.generic.lineLength': 'Die Zeilenlänge in diesem Feld darf {lineLength} nicht überschreiten',
  'form.error.generic.maxLength': 'Es sind maximal {max} Zeichen erlaubt',
  'form.error.generic.required': 'Bitte füll dieses Feld aus',
  'form.error.lastname.required': 'Bitte gib den Nachnamen ein',
  'form.error.newPassword.required': 'Bitte gib das neue Passwort ein',
  'form.error.newPasswordRepeat.match': 'Die Wiederholung stimmt nicht mit dem Passwort überein',
  'form.error.newPasswordRepeat.required': 'Bitte wiederhole das neue Passwort',
  'form.error.oldPassword.required': 'Bitte gib das aktuelle Passwort ein',
  'form.error.password.required': 'Bitte gib dein Passwort ein',
  'form.error.password.weak': 'Bitte wähle ein längeres Passwort und/oder verwende Zahlen, Symbole, Groß- und Kleinbuchstaben',
  'form.error.passwordRepeat.match': 'Die Wiederholung stimmt nicht mit dem Passwort überein',
  'form.error.passwordRepeat.required': 'Bitte wiederhole das Passwort',
  'form.error.projectCreate.name.required': 'Bitte gib einen Projektnamen ein',
  'form.error.projectCreate.videoFormat.required': 'Bitte wähle das Videoformat',
  'form.error.teamName.exists': 'Es existiert bereits ein Team mit diesem Namen',
  'form.error.token.resetPassword': 'Dein Link zum Zurücksetzen des Passworts ist ungültig',
  'form.error.token.setPassword': 'Dein Einladungs-Link ist ungültig',
  'form.error.user.emailExists': 'Diese E-Mail existiert bereits',
  'form.error.user.lastAdmin': 'Dies würde dazu führen, dass das Konto keinen Administrator mehr hat',
  'form.error': 'Fehler',
  'form.success': 'Deine Änderungen wurden gespeichert.',
  'home.createProjectDialog.button': 'Projekt erstellen',
  'home.createProjectDialog.description': 'Wähle das Team, in dem Du dein Projekt erstellen möchtest. Du kannst es später jederzeit verschieben.',
  'home.createProjectDialog.headline': 'Wähle ein Team aus',
  'home.createProjectDialog.teamSelect.before': 'Projekt erstellen in',
  'home.createProjectDialog.teamSelect.label': 'Team',
  'home.headline': 'Hey {name}, starte direkt mit Video',
  'home.latestProjects.empty.headline': 'Noch keine Aktivität',
  'home.latestProjects.empty.subline': 'Projekte erstellen und Mitwirkende einladen, um loszulegen',
  'home.latestProjects.title': 'Setze Deine Arbeit fort',
  'home.search.empty.description': 'Es wurden keine Projekte gefunden, die diesen Suchkriterien entsprechen',
  'home.search.empty.title': 'Kein Projekt gefunden',
  'home.search.label': 'Alle Projekte durchsuchen',
  'home.title': 'Home',
  'language.select.english': 'Englisch',
  'language.select.german': 'Deutsch',
  'mainNavigation.brandKit': 'Brand kit',
  'mainNavigation.createTeam': 'Team erstellen',
  'mainNavigation.home': 'Home',
  'mainNavigation.logout.admin': 'Identitätswechsel beenden',
  'mainNavigation.logout': 'Ausloggen',
  'mainNavigation.personal.projects': 'Meine Projekte',
  'mainNavigation.personal': 'Persönlich',
  'mainNavigation.projectTemplates': 'Projektvorlagen',
  'mainNavigation.teams': 'Teams',
  'mainNavigation.userMenu': 'Benutzermenü',
  'markdownTooltip.example.bold': 'fett',
  'markdownTooltip.example.italics': 'kursiv',
  'markdownTooltip.example.orderedList': 'Nummerierte Liste',
  'markdownTooltip.example.unorderedList': 'Nummerierte Liste',
  'markdownTooltip.link': 'Lerne mehr über Markdown.',
  'markdownTooltip.text': 'Dieses Feld unterstützt Markdown Formatierung. Es wird für Mitwirkende über den Teilen Link in voll formartierten Text umgewandelt.',
  'markdownTooltip.title': 'Formatierungshilfe',
  'markdownTooltip.toggle': 'Über die Formatierung',
  'mediaLibrary.addedSnackbar': 'Zur Medienbibliothek hinzugefügt',
  'mediaLibrary.addedSnackbar.view': 'Ansehen',
  'mediaLibrary.addToMediaLibrary': 'Zur Medienbibliothek hinzufügen',
  'mediaLibrary.asset.error': 'Defekte Datei: Wird in Videos nicht angezeigt.',
  'mediaLibrary.emptySearchResults.title': 'Keine Assets gefunden für „{search}“',
  'mediaLibrary.emptySearchResults.description': 'Versuche einen anderen Suchbegriff',
  'mediaLibrary.emptyState.description': 'Füge Bilder und Videos hinzu, um sie in Videos zu verwenden',
  'mediaLibrary.emptyState.title': 'Bisher gibt es noch keine Assets',
  'mediaLibrary.inlineRenameLabel': 'Wähle einen Namen für das Asset',
  'mediaLibrary.item.confirmDelete.content': 'Lösche das Asset dauerhaft. Dies kann nicht rückgängig gemacht werden.',
  'mediaLibrary.item.confirmDelete.title': 'Asset löschen',
  'mediaLibrary.item.description': 'Hinzugefügt am {createdAt} von {name}',
  'mediaLibrary.item.untitled': 'Unbenannt',
  'mediaLibrary.item.view': 'Anschauen',
  'mediaLibrary.orderBy.ALPHABETICAL': 'Alphabetisch',
  'mediaLibrary.orderBy.MOST_POPULAR': 'Beliebt',
  'mediaLibrary.orderBy.LAST_USED': 'Zuletzt verwendet',
  'mediaLibrary.pickAssetButton': 'Aus Bibliothek wählen',
  'mediaLibrary.rename.renameButton': 'Umbenennen',
  'mediaLibrary.rename.title': 'Asset umbenennen',
  'mediaLibrary.rename.subtitle': 'Wähle einen Namen für das Asset.',
  'mediaLibrary.search.error.title': 'Ein Fehler ist aufgetreten',
  'mediaLibrary.search.error.description': 'Es gab ein Problem beim Suchen. Bitte versuche es erneut.',
  'mediaLibrary.search.label': 'Suche nach Assets…',
  'mediaLibrary.search.total': '{count, plural, one {# Asset} other {# Assets}}',
  'mediaLibrary.search.total.image': '{count, plural, one {# Bild} other {# Bilder}}',
  'mediaLibrary.search.total.video': '{count, plural, one {# Video} other {# Videos}}',
  'mediaLibrary.upload': 'Hochladen',
  'mediaLibrary.upload.error': '{filename} konnte nicht hochgeladen werden.',
  'noAccess.goHome': 'Zur Startseite',
  'noAccess.message':
    'Entweder existiert {resource, select, Project {dieses Projekt} ProjectFolder {dieser Ordner} Team {dieses Team} User {dieser Benutzer} Page {diese Seite} other {diese Ressource} } nicht oder du hast keine Rechte diese Seite zu sehen. {resource, select, Team {Bitte den Team Eigentümer diesen Link zu überprüfen oder dich zum Team hinzuzufügen.} other {}}',
  'noAccess.title': '{resource, select, Project {Projekt} ProjectFolder {Ordner} Team {Team} User {Benutzer} Page {Seite} other {Ressource} } nicht gefunden',
  'noOption.description': 'Admins können neue Mitglieder einladen',
  'noOption.title': 'Niemand gefunden',
  'notification.settings.administrative.subtitle': 'Selbst wenn du dich von allen Emails abmeldest, senden wir dir essentielle Kommunikation wie Passwort Zurücksetzungen, Einladungen oder wichtige Account Veränderungen.',
  'notification.settings.administrative.title': 'Wir senden immer administrative Emails',
  'notification.settings.email.receiveNotifications': 'E-mail Benachrichtigungen erhalten',
  'notification.settings.email.section.projectActivity.newContribution': 'Neue Beiträge in einem deiner Projekte',
  'notification.settings.email.section.projectActivity.videoEngagement': 'Zuschauer*innen sehen dein Video',
  'notification.settings.email.section.projectActivity': 'Projekt Aktivität',
  'notification.settings.email.section.teamUpdates.newMembers': 'Änderungen an deiner Rolle und Zugang zu einem Team',
  'notification.settings.email.section.teamUpdates.roleChanges': 'Neue Mitglieder in deinem Account',
  'notification.settings.email.section.teamUpdates': 'Team Neuigkeiten',
  'notification.settings.email.section.weeklyDigest.weeklyNews': 'Wöchentliche Zusammenfassung von Aktivität und Performance innerhalb deines Unternehmens',
  'notification.settings.email.section.weeklyDigest': 'Wöchentliches Update',
  'notification.settings.email.title': 'E-mail Benachrichtigungen',
  'projectContributionConfiguration.briefing.description': 'Ein Video-Briefing kann die Antwortrate der Teilnehmer erheblich steigern',
  'projectContributionConfiguration.briefing.title': 'Video-Briefing',
  'projectContributionConfiguration.briefing.recordVideoButton': 'Video aufnehmen',
  'projectContributionConfiguration.initial.title': 'Bitte andere um Videos, Bilder und Bildschirmaufnahmen',
  'projectContributionConfiguration.initial.description': 'Erstelle ein Briefing, welches du per Link teilen kannst, um Beiträge einzusammeln',
  'projectContributionConfiguration.initial.createYourOwn.title': 'Erstelle dein eigenes',
  'projectContributionConfiguration.initial.createYourOwn.button': 'Starte mit leerem Dokument',
  'projectContributionConfiguration.initial.preset.title': 'Probiere eine unserer Vorlagen',
  'projectContributionConfiguration.list.requestsSection.title': 'Anfragen',
  'projectContributionConfiguration.filter.byContributors.label': 'Nach Mitwirkenden filtern',
  'projectContributionConfiguration.filter.byContributors.empty': 'Alle Mitwirkenden',
  'projectContributionConfiguration.filter.byContributors.notEmpty': '{count, plural, one {# Mitwirkender} other {# Mitwirkende}}',
  'projectContributionConfiguration.filter.byRequests.label': 'Nach Anfrage filtern',
  'projectContributionConfiguration.filter.byRequests.empty': 'Alle Anfragen',
  'projectContributionConfiguration.filter.byRequests.emptyCRTitle': 'Anfrage {index}',
  'projectContributionConfiguration.filter.byRequests.notEmpty': '{count, plural, one {# Anfrage} other {# Anfragen}}',
  'projectContributionConfiguration.filter.reset': 'Filter zurücksetzen',
  'projectContributionConfiguration.backToEditing': 'Zurück zum Editieren',
  'projectContributionConfiguration.pageTitle': 'Beiträge anfragen',
  'projectContributionConfiguration.preview': 'Vorschau',
  'projectContributionConfiguration.PreviewBriefingVideoDialog.title': 'Dein Video-Briefing',
  'projectContributionConfiguration.RecordVideoBriefing.description': 'Beschreibe den Teilnehmern kurz deine Videoidee und warum sie an deinem Video teilnehmen sollten.',
  'projectContributionConfiguration.RecordVideoBriefing.page.title': 'Video-Briefing aufzeichnen',
  'projectContributionConfiguration.RecordVideoBriefing.title': 'Fasse deine Videoidee zusammen',
  'projectContributionConfiguration.requestContentButton': 'Beiträge anfragen',
  'projectContributionConfiguration.recordings.emptyState.title': 'Keine Aufnahmen bisher',
  'projectContributionConfiguration.recordings.emptyState.description': 'Niemand hat etwas zu deiner Anfrage beigetragen',
  'projectContributionConfiguration.section.dataAndPrivacy.title': 'Daten und Privatsphäre',
  'projectContributionConfiguration.section.general.title': 'Generell',
  'projectContributionConfiguration.settings.createSceneOnContribution.title': 'Neue Beiträge im Editierbereich hinzufügen',
  'projectContributionConfiguration.settings.createSceneOnContribution.description': 'Alle neuen Beiträge werden im Bereich „Editieren“ hinzugefügt, sobald sie eingehen',
  'projectContributionConfiguration.settings.acceptResponses.title': 'Antworten annehmen',
  'projectContributionConfiguration.settings.acceptResponses.description': 'Link zu den neuen Beiträgen öffnen',
  'projectContributionConfiguration.settings.collectDetails.title': 'Informationen der Mitwirkenden einholen',
  'projectContributionConfiguration.settings.collectDetails.description': 'Den Namen und die E-Mail-Adresse aller Mitwirkenden einholen',
  'projectContributionConfiguration.settings.requireConsent.banner.title': 'Einverständniserklärung ist deaktiviert',
  'projectContributionConfiguration.settings.requireConsent.banner.description': 'Die Einverständniserklärung wurde standardmäßig für neue Projekte in diesem Account deaktiviert.',
  'projectContributionConfiguration.settings.requireConsent.banner.configureButtonLabel': 'Konfigurieren',
  'projectContributionConfiguration.settings.requireConsent.description': 'Bitte Beitragende dein Einverständnisdokument zu unterzeichnen',
  'projectContributionConfiguration.settings.requireConsent.title': 'Zustimmung zur Aufnahme erforderlich',
  'projectContributionConfiguration.settings.requireConsent.notConfigured.admin': 'Die Einverständniserklärung wurde für diesen Account noch nicht eingerichtet. <link>Mehr erfahren</link>',
  'projectContributionConfiguration.settings.requireConsent.notConfigured.nonAdmin': 'Die Einverständniserklärung wurde für diesen Account noch nicht eingerichtet. Sie kann von Administratoren deines Teams aktiviert werden <link>Mehr erfahren</link>',
  'projectContributionConfiguration.settings.requireConsent.notConfigured.configureButton': 'Einverständniserklärung konfigurieren',
  'projectContributionConfiguration.settings.requireConsent.notConfigured.title': 'Einverständniserklärung anfordern',
  'projectContributionConfiguration.settings.showOthersContributions.title': 'Alle Beiträge anzeigen',
  'projectContributionConfiguration.settings.showOthersContributions.description': 'Teilnehmer können die Beiträge von anderen Teilnehmer in den Anfragen sehen',
  'projectContributionConfiguration.tabs.requests': 'Beiträge',
  'projectContributionConfiguration.tabs.recordings': 'Aufnahmen',
  'projectContributionConfiguration.tabs.settings': 'Einstellungen',
  'projectContributionConfiguration.usePresetButton': 'Vorlage verwenden',
  'projectContributionConfiguration.videoBriefing.confirmDelete.title': 'Video-Briefing löschen',
  'projectContributionConfiguration.videoBriefing.moreOptionsLabel': 'Mehr Optionen',
  'projectContribution.AddContributionRequestCard.text': 'Anfrage hinzufügen',
  'projectContribution.CreateContributionRequestListForm.addListTitle': 'Zur eigenen Liste hinzufügen',
  'projectContribution.CreateContributionRequestListForm.addListInputLabel': 'Neue eigene Liste hinzufügen',
  'projectContribution.CreateContributionRequestListForm.emptyStateText': 'Starte eine Sharing-Liste um die Anfrage mit einzelnen oder Gruppen zu teilen',
  'projectContributions.ContributionRequestCard.addToListChipTitle': 'Zur eigenen Liste hinzufügen',
  'projectContributions.ContributionRequestCard.contributionsCount': '{count, plural, =0 {Noch keine Beiträge} one {# Beitrag} other {# Beiträge}}',
  'projectContributions.ContributionRequestCard.form.instructions.label': 'Instruktionen',
  'projectContributions.ContributionRequestCard.form.instructions.placeholder': 'Gebe zusätzliche Instruktionen für die Aufnahme. Wo soll gefilmt werden? Welche Punkte müssen genannt werden? Wie soll die Position zur Kamera sein?',
  'projectContributions.ContributionRequestCard.form.title.label': 'Titel',
  'projectContributions.ContributionRequestCard.form.title.placeholder': 'Gib einen Titel für die Anfrage ein',
  'projectContributions.ContributionRequestCard.crl.removeFromGroupDialog.title': 'Aus der Liste entfernen',
  'projectContributions.ContributionRequestCard.crl.removeFromGroupDialog.description': 'Möchtest du diese Anfrage wirklich aus der Liste “{name}” entfernen?',
  'projectContributions.ContributionRequestListDialog.share.appCode': 'App code',
  'projectContributions.ContributionRequestListDialog.share.codeCopiedSnackbar': 'Code für {for} in die Zwischenablage kopiert',
  'projectContributions.ContributionRequestListDialog.share.copyLink': 'Link kopieren',
  'projectContributions.ContributionRequestListDialog.share.defaultList': 'Alle Anfragen',
  'projectContributions.ContributionRequestListDialog.share.emailsSentSnackbar.everyone': 'alle',
  'projectContributions.ContributionRequestListDialog.share.emailsSentSnackbar': 'Anfrage für Beiträge verschickt an {listName}',
  'projectContributions.ContributionRequestListDialog.share.linkCopiedSnackbar': 'Link für {for} in die Zwischenablage kopiert',
  'projectContributions.ContributionRequestListDialog.share.qrCode': 'QR-Code',
  'projectContributions.ContributionRequestListDialog.share.sendEmails': 'Anfrage senden',
  'projectContributions.contributions.addToVideoButton': 'Zum Video hinzufügen',
  'projectContributions.contributions.bulk.addToProject': 'Füge {count, plural, one {# Beitrag} other {# Beiträge}} zum Projekt hinzu',
  'projectContributions.contributions.bulk.label': 'Wähle „{name}" zur Massenbearbeitung aus',
  'projectContributions.contributions.bulk.contributionsSelected': '{count, plural, =0 {# Beiträge} one {# Beitrag} other {# Beiträge}} ausgewählt',
  'projectContributions.contributions.bulk.selectAll': 'Alle auswählen',
  'projectContributions.contributions.deleteAssetConfirmation.title': 'Beitrag löschen',
  'projectContributions.contributions.deleteMultipleAssetConfirmation.title': '{contributionsCount, plural, one {Beitrag löschen} other {# Beiträge löschen}}',
  'projectContributions.contributions.deleteMultipleAssetConfirmation.description.noScenes': 'Bist du sicher, dass du {contributionsCount, plural, one {diesen Beitrag} other {diese Beiträge}} löschen möchtest?',
  'projectContributions.contributions.deleteMultipleAssetConfirmation.description':
    '{contributionsCount, plural, one {Dieser Beitrag wird} other {Diese Beiträge werden}} in {scenesCount, plural, one {# Szene} other {# Szenen}} verwendet. Wenn du den Beitrag löscht werden auch alle Szenen mit diesem Beitrag entfernt. Bist du sicher, dass du {contributionsCount, plural, one {diesen Beitrag} other {diese Beiträge}} löschen möchtest?',
  'projectContributions.contributions.emptyState.description': 'Warte bis Leute etwas beitragen',
  'projectContributions.contributions.emptyState.title': 'Keine Aufnahmen bisher',
  'projectContributions.contributions.imageContribution.alt': 'Bildbeitrag erstellt am {date}',
  'projectContributions.contributions.includedTooltip': 'Inklusive',
  'projectContributions.contributions.snackbar.contributionAdded': 'Szene zum Projekt hinzugefügt. Du findest sie im Bereich "Editieren".',
  'projectContributions.RequestRecordingsButton.label': 'Beiträge anfragen',
  'projectCreate.form.button.submit': 'Projekt erstellen',
  'projectCreate.form.name.defaultValue': 'Unbenanntes Video',
  'projectCreate.form.name.label': 'Wähle einen Namen',
  'projectCreate.form.name.placeholder': 'Wie lautet dein Projektname?',
  'projectCreate.header': 'Neues Video erstellen',
  'projectCreate.header.copy': 'Kopiere {scenesCount, plural, one {Szene} other {# Szenen}} in ein neues Video',
  'projectCreate.header.move': 'Verschiebe {scenesCount, plural, one {Szene} other {# Szenen}} in ein neues Video',
  'projectCreate.projectTemplates.blankProjectHeader': 'Neues leeres Projekt',
  'projectCreate.projectTemplates.projectTemplatesHeader': 'Projektvorlagen',
  'projectCreate.videoFormat.header': 'Wähle das Videoformat',
  'projectCreate.videoFormat.Horizontal.title': 'Horizontal 16:9',
  'projectCreate.videoFormat.SocialMedia.title': 'Porträt 4:5',
  'projectCreate.videoFormat.Square.title': 'Quadratisch 1:1',
  'projectCreate.videoFormat.Vertical.title': 'Vertikal 9:16',
  'ProjectCut.TrimmingSections.buttons.split': 'Teilen',
  'ProjectCut.TrimmingSections.buttons.delete': 'Löschen',
  'ProjectCut.TrimmingSections.handle': 'Trimmgriff',
  'ProjectCut.TrimmingSections.scrubber': 'Schieberegler suchen',
  'ProjectCut.TrimmingSections.tooltip.extendSequence': 'Sequenz erweitern',
  'ProjectCut.TrimmingSections.tooltip.joinSequences': 'Sequenzen zusammenführen',
  'ProjectCut.TrimmingSections.tooltips.zoomIn': 'Vergrößern',
  'ProjectCut.TrimmingSections.tooltips.zoomLevel': 'Zoomstufe',
  'ProjectCut.TrimmingSections.tooltips.zoomOut': 'Verkleinern',
  'projectDesign.OutroTextInput.label': 'Text',
  'projectDesign.OutroTextInput.placeholder': 'Max. {lines} Zeilen mit je {lineLength} Zeichen',
  'projectDesign.panel.headline': 'Template',
  'projectDesign.templateForm.background.black': 'Schwarz',
  'projectDesign.templateForm.background.blurred': 'Weichgezeichnet',
  'projectDesign.templateForm.background.primary': 'Primärfarbe',
  'projectDesign.templateForm.background': 'Hintergrund',
  'projectDesign.templateForm.backgroundTooltip.black': 'Der Leerraum wird mit schwarzer Farbe gefüllt',
  'projectDesign.templateForm.backgroundTooltip.blurred': 'Der Leerraum wird weichgezeichnet',
  'projectDesign.templateForm.backgroundTooltip.primary': 'Der Leerraum wird mit der gewählten Primärfarbe gefüllt',
  'projectDesign.templateForm.changeButton': '<hidden>Template</hidden> ändern',
  'projectDesign.templateForm.headline': 'Template',
  'projectDesign.themeForm.changeTheme': '<hidden>Theme</hidden> ändern',
  'projectDesign.themeForm.colorPrimary.label': 'Primärfarbe',
  'projectDesign.themeForm.colorSecondary.label': 'Sekundärfarbe',
  'projectDesign.themeForm.error': 'Beim Aktualisieren des Themes ist ein Fehler aufgetreten.',
  'projectDesign.themeForm.headline.colors': 'Farben',
  'projectDesign.themeForm.headline.logo': 'Logo',
  'projectDesign.themeForm.headline.theme': 'Theme',
  'projectDesign.themeForm.intro': 'Intro',
  'projectDesign.themeForm.outro': 'Outro',
  'projectDesign.themeForm.panel.headline': 'Themes',
  'projectDesign.themeForm.panel.noThemes': 'Du hast noch kein Theme. Gehe in die Design Einstellungen um dein erstes zu erstellen.',
  'projectDesign.themeForm.panel.unnamedTheme': 'Unbenanntes Theme',
  'projectDesign.themeForm.videoOutro': 'Video Outro',
  'projectEdit.colorPicker.pickButton': 'Wähle die Farbe {color}',
  'projectEdit.colorPicker.setButton': 'Farbe wählen',
  'projectEdit.copySceneToProject.error': 'Szene kann nicht eingefügt werden. Entweder wurde sie gelöscht oder du hast nicht die nötigen Zugriffsrechte.',
  'projectEdit.durationInput.setButton': 'Dauer wählen',
  'projectEdit.editorTools.EditSceneSubtitles.button.text': 'Untertitel',
  'projectEdit.empty.addScene': 'Füge eine Szene hinzu',
  'projectEdit.empty.archived.description': 'Dieses archivierte Projekt enthält keine Szenen. Reaktiviere es, um Szenen hinzuzufügen.',
  'projectEdit.empty.archived.title': 'Leeres Projekt',
  'projectEdit.empty': 'Du kannst Anweisungen für Szenen erstellen und sie mithilfe des Drehbuchs mit anderen Mitwirkenden teilen',
  'projectEdit.EnhancedAudio.error': 'Etwas ist schief gelaufen. Bitte versuche es erneut.',
  'projectEdit.EnhancedAudio.mainVideoAssetNotReady': 'Die Hintergrundgeräusche können entfernt werden, sobald wir dein Video optimiert haben.',
  'projectEdit.EnhancedAudio.remove': 'Hintergrundgeräusche entfernen',
  'projectEdit.EnhancedAudio.removing': 'Hintergrundgeräusche entfernen…',
  'projectEdit.EnhancedAudio.title': 'Audio Tools',
  'projectEdit.manipulateAsset.crop': 'Croppen',
  'projectEdit.noUpdateProjectPermission.headline': 'Bisher keine Szenen',
  'projectEdit.noUpdateProjectPermission.subline': 'Bitten deinen Administrator um Bearbeitungsrechte, um mit der Erstellung von Szenen beginnen zu können',
  'projectEdit.overlays.headline': 'Elemente Hinzufügen',
  'projectEdit.scene.edit.button': 'Szene „{title}“ bearbeiten',
  'projectEdit.SceneAudioButton.noAudio': 'Kein Ton',
  'projectEdit.SceneAudioButton.sceneAudio': 'Audio',
  'projectEdit.SceneAudioPanelContent.sceneAudioMute.title': 'Szene stummschalten',
  'projectEdit.SceneAudioPanelContent.sceneAudioType.originalSceneAudio.button': 'Originalspur abspielen',
  'projectEdit.SceneAudioPanelContent.sceneAudioType.originalSceneAudio': 'Original',
  'projectEdit.SceneAudioPanelContent.sceneAudioType.uploadedAudio': 'Hochgeladen',
  'projectEdit.SceneAudioPanelContent.SceneAudioUploader.hint': 'Dein Audio wird auf die Dauer der Szene zugeschnitten',
  'projectEdit.SceneAudioPanelContent.title': 'Audiospur',
  'projectEdit.SceneColorNoAsset.text': 'Füge eine Hintergrundfarbe hinzu',
  'projectEdit.SceneEditorNotes.button.text': 'Notizen',
  'projectEdit.SceneEditorNotes.overlay.header': 'Notizen',
  'projectEdit.SceneEditorNotes.overlay.placeholder': 'Füge hier deine Notizen hinzu',
  'projectEdit.sceneForm.color': 'Farbe',
  'projectEdit.sceneForm.image': 'Bild',
  'projectEdit.sceneForm.screenRecording': 'Bildschirmaufnahme',
  'projectEdit.sceneForm.video': 'Video',
  'projectEdit.sceneForm.tabs.new': 'Neue',
  'projectEdit.sceneForm.tabs.requests': 'Anfragen',
  'projectEdit.textElement.addText.tooltip': 'Du benötigst mindestens eine Sekunde Platz, um an dieser Stelle Text einzufügen',
  'projectEdit.textElement.addText': 'Text hinzufügen',
  'projectEdit.textElement.tooltip': '{count, plural, one {1 Textelement} other {# Textelemente}}',
  'projectEdit.trimming.cut': 'Schneiden',
  'projectEditor.hint.music.short': 'Musik wird nach dem Export hinzugefügt',
  'projectEditor.hint.hidden': 'Diese Szene ist vom Video ausgeschlossen. <link>Anzeigen</link>',
  'projectEditor.hint.hidden.short': 'Ausgeschlossen: <link>Anzeigen</link>',
  'projectEditor.playerActions.gotoNextScene': 'Weiter zur nächsten Szene',
  'projectEditor.playerActions.gotoPreviousScene': 'Zurück zur vorherigen Szene',
  'projectEditor.sceneAssetThumbs.popover.actions.addTransition': 'Übergang hinzufügen',
  'projectEditor.sceneAssetThumbs.popover.actions.addTransitions': 'Allen Szenen hinzufügen',
  'projectEditor.sceneAssetThumbs.popover.actions.delete': 'Löschen',
  'projectEditor.sceneAssetThumbs.popover.actions.deleteAsset': 'Asset entfernen',
  'projectEditor.sceneAssetThumbs.popover.actions.deleteColor': 'Farbe entfernen',
  'projectEditor.sceneAssetThumbs.popover.actions.deleteTransition': 'Übergang löschen',
  'projectEditor.sceneAssetThumbs.popover.actions.download.image': 'Bild herunterladen',
  'projectEditor.sceneAssetThumbs.popover.actions.download.video': 'Video herunterladen',
  'projectEditor.sceneAssetThumbs.popover.actions.hideScene': 'Aus Video ausblenden',
  'projectEditor.sceneAssetThumbs.popover.actions.insertScene': 'Neue Szene',
  'projectEditor.sceneAssetThumbs.popover.actions.moveToProject': 'In Projekt verschieben',
  'projectEditor.sceneAssetThumbs.popover.actions.showScene': 'Im Video zeigen',
  'projectEditor.sceneAssetThumbs.transition.isLastIncludedScene': 'Der Übergang wird für die letzte sichtbare Szene nicht angezeigt',
  'projectEditor.sceneAssetThumbs.transition': 'Übergang',
  'projectEditor.tools.introOutro': '{type} bearbeiten',
  'projectFolder.personal': 'Meine Projekte',
  'projectManipulateAsset.controls.mirror': 'Spiegeln',
  'projectManipulateAsset.handle.crop.bottom': 'Zuschneidegriff unten',
  'projectManipulateAsset.handle.crop.left': 'Zuschneidegriff links',
  'projectManipulateAsset.handle.crop.right': 'Zuschneidegriff rechts',
  'projectManipulateAsset.handle.crop.top': 'Zuschneidegriff oben',
  'projectManipulateAsset.handle.drag': 'Ziehgriff',
  'projectManipulateAsset.handle.rotate': 'Rotationsgriff',
  'projectManipulateAsset.handle.scale.bottomLeft': 'Skalierungsgriff unten links',
  'projectManipulateAsset.handle.scale.bottomRight': 'Skalierungsgriff unten rechts',
  'projectManipulateAsset.handle.scale.topLeft': 'Skalierungsgriff oben links',
  'projectManipulateAsset.handle.scale.topRight': 'Skalierungsgriff oben rechts',
  'projectMusic.music.headline.current': 'Musik',
  'projectMusic.music.headline.public': 'Musiksammlung',
  'projectMusic.music.navigation.all': 'Alle Musik',
  'projectMusic.music.navigation.private': 'Meine Musik',
  'projectMusic.music.navigation.public': 'Von cofenster',
  'projectMusic.music.hint': 'Wähle aus der Musiksammlung die passende Musik für dein Video aus',
  'projectMusic.music.subline': 'Die Musik wird beim Export hinzugefügt und die Lautstärke wird automatisch reduziert wenn gesprochen wird.',
  'projectMusic.music.remove': '{name} Entfernen',
  'projectMusic.music.select': '{name} Auswählen',
  'projectMusic.music.private': 'Meine Musik',
  'projectMusic.music.progress': 'Aktueller Soundtrack-Fortschritt',
  'projectMusic.music.public': 'Von cofenster',
  'projectMusic.MusicLoudnessForm.High.description': 'Besonders zu empfehlen, wenn das Gesprochene laut ist oder die Musik im Fokus steht',
  'projectMusic.MusicLoudnessForm.High.title': 'Laut',
  'projectMusic.MusicLoudnessForm.Medium.description': 'Die Tonspur der Aufnahme steht im Mittelpunkt, besonders wenn sie laut und klar genug ist',
  'projectMusic.MusicLoudnessForm.Medium.title': 'Normal',
  'projectMusic.MusicLoudnessForm.Soft.description': 'Empfohlen als leichte Hintergrundmusik oder wenn leise gesprochen wird',
  'projectMusic.MusicLoudnessForm.Soft.title': 'Ruhig',
  'projectMusic.volume.title': 'Musiklautstärke',
  'projectTemplate.defaultName': 'Aus Vorlage {sourceName}',
  'projectTemplate.pageNotification': 'Du editierst eine {icon} Vorlage',
  'projectTemplates.createPopoverText': 'Vorlage erstellen',
  'projectTemplates.createProjectTemplate.form.nameTitle': 'Wähle einen Namen',
  'projectTemplates.createProjectTemplate.form.formatTitle': 'Wähle das Videoformat',
  'projectTemplates.createProjectTemplate.form.submitButton': 'Vorlage erstellen',
  'projectTemplates.createProjectTemplate.page.title': 'Vorlage erstellen',
  'projectTemplates.createProjectTemplate.snackbar': 'Projekt als Vorlage gespeichert. <link>Editieren</link>',
  'projectTemplates.createProjectFromProjectTemplateDialog.createButton': 'Projekt erstellen',
  'projectTemplates.createProjectFromProjectTemplateDialog.description': 'Wähle das Team, in dem du dein Projekt erstellen möchtest. Du kannst es später jederzeit verschieben.',
  'projectTemplates.createProjectFromProjectTemplateDialog.headline': 'Wähle ein Team aus',
  'projectTemplates.createProjectTemplateFromProject.dialog.form.description': 'Beschreibung (optional)',
  'projectTemplates.createProjectTemplateFromProject.dialog.form.descriptionPlaceholder': 'Erkläre, wann und wie diese Vorlage nützlich ist…',
  'projectTemplates.createProjectTemplateFromProject.dialog.form.name': 'Vorlagenname',
  'projectTemplates.createProjectTemplateFromProject.dialog.headline': 'Als Vorlage speichern',
  'projectTemplates.createProjectTemplateFromProject.dialog.subheadline': 'Speichere häufig verwendete Projekte als wiederverwendbare Vorlagen. Du und dein Team können Vorlagen nutzen, um Videos schneller in eurem bevorzugten Stil zu erstellen. Das Originalvideo bleibt so wie es ist.',
  'projectTemplates.createProjectTemplateFromProject.dialog.submitButton': 'Als Vorlage speichern',
  'projectTemplates.createProjectTemplateForm.description': 'Beschreibung (optional)',
  'projectTemplates.createProjectTemplateForm.descriptionPlaceholder': 'Erkläre, wann und wie diese Vorlage nützlich ist…',
  'projectTemplates.createProjectTemplateForm.name': 'Vorlagenname',
  'projectTemplates.deleteDialog.content': 'Bist du sicher, dass du diese Vorlage löschen möchtest?',
  'projectTemplates.deleteDialog.title': 'Vorlage löschen',
  'projectTemplates.deleteProjectTemplate.snackbar.success': 'Deine Vorlage wurde gelöscht',
  'projectTemplates.duplicateProjectTemplate.snackbar.success': 'Deine Vorlage wurde dupliziert',
  'projectTemplates.editTemplateConfirmDialog.confirm': 'Vorlage editieren',
  'projectTemplates.editTemplateConfirmDialog.content': 'Du editierst eine Vorlage, die deinem gesamten Team zur Verfügung steht. Neue Projekte, die mit dieser Vorlage erstellt werden, basieren auf deinen Änderungen.',
  'projectTemplates.editTemplateConfirmDialog.title': 'Vorlage editieren',
  'projectTemplates.page.createEmptyTemplateButton': 'Vorlage erstellen',
  'projectTemplates.page.search.label': 'Suche nach Vorlagen',
  'projectTemplates.page.search.noResults.description': 'Versuche einen anderen Suchbegriff',
  'projectTemplates.page.search.noResults.noSearch.title': 'Keine Vorlagen gefunden',
  'projectTemplates.page.search.noResults.title': 'Keine Vorlagen gefunden für "{search}"',
  'projectTemplates.page.search.resultsCount': '{count, plural, =0 {Keine Vorlagen} one {# Vorlage} other {# Vorlagen}}',
  'projectTemplates.page.title': 'Vorlagen',
  'projectTemplates.previewTemplate.noScenes.description': 'Diese Vorlage hat keine Szenen',
  'projectTemplates.previewTemplate.noScenes.title': 'Keine Szenen für die Vorschau',
  'projectTemplates.projectTile.actions.useTemplate': 'Vorlage verwenden',
  'projectTemplates.projectTile.openPreview': 'Vorschau',
  'projectTemplates.renameProjectTemplateDialog.headline': 'Vorlage umbenennen',
  'projectTemplates.search.error.description': 'Bei der Suche ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  'projectTemplates.search.error.title': 'Ein Fehler ist aufgetreten “{search}”',
  'projects.bulkSelector.projects': '{count, plural, =0 {# Projekte} one {# Projekt} other {# Projekte}} ausgewählt',
  'projects.bulkSelector.selectAll': 'Alle auswählen',
  'projects.buttons.folder': 'Ordner hinzufügen',
  'projects.buttons.project': 'Projekt erstellen',
  'projects.filters.createdBy.ANYONE_BUT_SELF': 'Nicht von mir erstellt',
  'projects.filters.createdBy.ANYONE': 'Von jedem erstellt',
  'projects.filters.createdBy.label': 'Projekte filtern nach',
  'projects.filters.createdBy.SELF': 'Von mir erstellt',
  'projects.filters.status.ACTIVE': 'Aktiv',
  'projects.filters.status.ALL': 'Alle',
  'projects.filters.status.ARCHIVED': 'Archiviert',
  'projects.filters.status.label': 'Projekte filtern nach',
  'projects.folderCard.videos': '{count, plural, =0 {Keine Videos} one {# Video} other {# Videos}}',
  'projects.headline.folders': 'Ordner',
  'projects.headline.projects': 'Projekte',
  'projects.noArchived.headline': 'Keine archivierten Projekte',
  'projects.noArchived.subline': 'Wenn du ein Projekt archivierst wird es hier angezeigt',
  'projects.projectCard.bulk.label': 'Wähle „{name}“ zur Massenbearbeitung aus',
  'projects.projectCard.creator': 'Von {name}',
  'projects.projectCard.edit': '{name} Bearbeiten',
  'projects.projectCard.updatedAt': 'Bearbeitet {timeAgo}',
  'projects.projectCard.uploadedScenes': 'Hochgeladene Szenen',
  'projects.projectCard.watch': '{name} Anschauen',
  'ProjectSceneOverlays.buttons.addMediaOverlay': 'Footage hinzufügen',
  'ProjectSceneOverlays.buttons.addOverlays': 'Elemente hinzufügen',
  'ProjectSceneOverlays.buttons.uploadFile': 'Eine Datei hochladen',
  'ProjectSceneOverlays.feedback.processing': 'Datei wird bearbeitet…',
  'ProjectSceneOverlays.feedback.uploading': 'Datei wird hochgeladen…',
  'projectSceneOverlays.lastTimeSaved': 'Zuletzt gespeichert um {timeAgo}',
  'ProjectSceneOverlays.MainTimeline.muted': 'Die Szene ist stummgeschaltet',
  'projectSceneOverlays.savingChanges': 'Wird gespeichert…',
  'projectSceneOverlays.tooltips.maxOverlays': 'Das Maximum von {max} Ebenen ist erreicht',
  'projectsFolder.noProjectsAndPermission.headline': 'Keine Projekte hier',
  'projectsFolder.noProjectsAndPermission.subline': 'Frage deinen Administrator nach Editierrechten um deine eigenen zu starten',
  'projectsFolderList.showLess': 'Weniger anzeigen',
  'projectsFolderList.showMore': 'Weitere Vorlagen',
  'projectStoryboard.AddSceneCard.text': 'Szene hinzufügen',
  'projectSubtitles.CreateTranscriptionCard.buttonText': 'Untertitel hinzufügen',
  'projectSubtitles.CreateTranscriptionCard.headline': 'Füge Untertitel zu deinem Video hinzu',
  'projectSubtitles.CreateTranscriptionCard.subline': 'Du kannst nachträglich Übersetzungen hinzufügen',
  'projectSubtitles.deleteTranscriptionDialog.confirm': 'Untertitel löschen',
  'projectSubtitles.deleteTranscriptionDialog.description': 'Du musst neue Untertitel erstellen, bevor du übersetzen kannst. Bist du sicher, dass du deine Untertitel löschen möchtest?',
  'projectSubtitles.deleteTranscriptionDialog.text1': '{translationsCount, plural, =0 {} one {Deine Übersetzung wird} other {Deine {translationsCount} Übersetzungen werden}} ebenfalls gelöscht.',
  'projectSubtitles.deleteTranscriptionDialog.text2': 'Bist du sicher, dass du deine Untertitel löschen möchtest?',
  'projectSubtitles.deleteTranscriptionDialog.title': 'Untertitel löschen',
  'projectSubtitles.deleteTranslation.confirm': 'Übersetzung löschen',
  'projectSubtitles.deleteTranslation.description': 'Dein gesamter Fortschritt geht verloren. Bist du sicher, dass du deine Übersetzung löschen möchtest?',
  'projectSubtitles.deleteTranslation.title': 'Übersetzung löschen',
  'projectSubtitles.editDialog.editSubtitle': 'Untertitel bearbeiten',
  'projectSubtitles.editDialog.editTranslation': 'Übersetzung bearbeiten',
  'projectSubtitles.error.buttonText': 'Request support',
  'projectSubtitles.error.description': 'Ein technisches Problem hat die Erstellung gestoppt',
  'projectSubtitles.error.headline': 'Auf unserer Seite ist ein technisches Problem aufgetreten',
  'projectSubtitles.missingReviewDialog.continueToAdd': 'Übersetzung hinzufügen',
  'projectSubtitles.missingReviewDialog.description': 'Die Übersetzungen basieren auf deinen Untertiteln. Um das beste Ergebnis für deine Übersetzungen zu erzielen, überprüfe zuerst deine Untertitel.',
  'projectSubtitles.missingReviewDialog.review': 'Untertitel überprüfen',
  'projectSubtitles.missingReviewDialog.title': 'Untertitel überprüfen',
  'projectSubtitles.recreateSubtitles.buttonText': 'Neu erstellen',
  'projectSubtitles.recreateSubtitles.header': 'Untertitel neu erstellen',
  'projectSubtitles.recreateSubtitles.text1': 'Deine Untertitel {translationsCount, plural, =0 {} one {und deine Übersetzung} other {und deine {translationsCount} Übersetzungen}} werden gelöscht.',
  'projectSubtitles.recreateSubtitles.text2': ' Bist du sicher, dass du deine Untertitel neu erstellen möchtest?',
  'projectSubtitles.recreateTranslation.buttonText': 'Neu erstellen',
  'projectSubtitles.recreateTranslation.header': 'Übersetzung neu erstellen',
  'projectSubtitles.recreateTranslation.text': 'Dein gesamter Fortschritt geht verloren. Bist du sicher, dass du deine Übersetzung neu erstellen möchtest?',
  'projectSubtitles.RenderVideoCard.buttonText': 'Video exportieren',
  'projectSubtitles.RenderVideoCard.headline': 'Video zuerst exportieren',
  'projectSubtitles.RenderVideoCard.subline': 'Bitte exportiere dein Video zuerst, bevor du Untertitel hinzufügst',
  'projectSubtitles.status.exportError': 'Error',
  'projectSubtitles.status.exportInProgress': 'Exportieren…',
  'projectSubtitles.status.transcriptionInProgress.description': 'Untertitel für diese Szene werden automatisch erstellt',
  'projectSubtitles.status.transcriptionInProgress': 'Untertitel werden erstellt',
  'projectSubtitles.status.translationInProgress': 'Übersetzen…',
  'projectSubtitles.status.undatingInProgress': 'Aktualisieren…',
  'projectSubtitles.subtitleInputLabel': 'Untertitel zwischen {start} und {end}',
  'projectSubtitles.subtitles': 'Untertitel',
  'projectSubtitles.subtitlesCard.actionButtons.download': 'Herunterladen',
  'projectSubtitles.subtitlesCard.actionButtons.edit': 'Bearbeiten',
  'projectSubtitles.subtitlesCard.actionButtons.export': 'Video exportieren',
  'projectSubtitles.subtitlesCard.actionButtons.update': 'Video aktualisieren',
  'projectSubtitles.subtitlesCard.popover.deleteSubtitle': 'Untertitel löschen',
  'projectSubtitles.subtitlesCard.popover.deleteTranslation': 'Übersetzung löschen',
  'projectSubtitles.subtitlesCard.popover.regenerateSubtitle': 'Untertitel neu erstellen',
  'projectSubtitles.subtitlesCard.popover.regenerateTranslation': 'Übersetzung neu erstellen',
  'projectSubtitles.subtitlesCard.popover.srtDownload': '.srt herunterladen',
  'projectSubtitles.subtitlesCard.popover.vttDownload': '.vtt herunterladen',
  'projectSubtitles.subtitlesChangedDialog.description': 'Änderungen an den Untertiteln wurden vorgenommen. Um diese Änderungen einzubeziehen, aktualisiere dein Video oder lade die aktuelle Version herunter.',
  'projectSubtitles.subtitlesChangedDialog.download': 'Video herunterladen',
  'projectSubtitles.subtitlesChangedDialog.headline': 'Video aktualisieren',
  'projectSubtitles.subtitlesChangedDialog.update': 'Aktualisieren',
  'projectSubtitles.subtitlesNodeAddLabel': 'Zeile hinzufügen',
  'projectSubtitles.subtitlesNodeMergeLines': 'Zeilen zusammenfügen',
  'projectSubtitles.subtitlesNodeSplitLine': 'Zeile spliten',
  'projectSubtitles.subtitlesNodeDeleteLabel': 'Zeile löschen',
  'projectSubtitles.subtitlesTimestampLabel': 'Untertitel „{type}“ Zeit um {time}',
  'projectSubtitles.translation.new': 'Übersetzte Untertitel hinzufügen',
  'projectSubtitles.translations': 'Übersetzungen',
  'projectTranscription.button': 'Untertitel erstellen',
  'projectTranscription.header': 'Untertitel hinzufügen',
  'projectTranscription.languageSelect.label': 'Gesprochene Sprache',
  'projectTranscription.languageSelect.placeholder': 'Gesprochene Sprache auswählen',
  'projectTranscription.progress.description': 'Sprache wird in Text umgewandelt',
  'projectTranscription.progress.header': 'Untertitel für dein Video werden erstellt',
  'projectTranscription.title': 'Welche Sprache wird in deinem Video gesprochen?',
  'projectTranslation.banner.description': 'Je genauer und sauber überprüft deine Untertitel sind, desto besser ist die Übersetzung!',
  'projectTranslation.banner.title': 'So erzielst du das beste Ergebnis für deine Übersetzung',
  'projectTranslation.button': 'Übersetzung erstellen',
  'projectTranslation.header': 'Übersetzung hinzufügen',
  'projectTranslation.languageSelect.label': 'Übersetzungssprache',
  'projectTranslation.languageSelect.placeholder': 'Übersetzungssprache',
  'projectTranslation.loading.description': 'Übersetzung basierend auf deinen Untertiteln',
  'projectTranslation.loading.title': 'Übersetzung wird erstellt',
  'projectTranslation.title': 'In welche Sprache möchtest du die Untertitel übersetzen?',
  'projectVideo.exportListCard.moreMenu.editSubtitles': 'Untertitel bearbeiten',
  'projectVideo.exportListCard.moreMenu.editVideo': 'Video bearbeiten',
  'projectVideo.exportListCard.title.originalVideo': 'Originalvideo',
  'projectVideo.exportListCard.title.subtitledVideo': '{language} Untertitel',
  'projectVideo.notYetRendered.subtitle': 'Lass uns ein wenig zaubern und dein Video fertigstellen.',
  'projectVideo.notYetRendered.title': 'Exportiere dein Video',
  'projectVideo.notYetRendered.withSubtitles.subtitle': 'Exportiere das Video mit Untertiteln in {language}',
  'projectVideo.notYetRendered.withSubtitles.title': 'Exportiere dein untertiteltes Video',
  'projectVideo.renderButton.emergency': 'Unsere Dienste werden einer außerplanmäßigen Wartung unterzogen. Der Export ist gleich wieder möglich.',
  'projectVideo.renderButton.tooLong.absolute': 'Leider ist dein Video zu lang, um von uns gerendert werden zu können ({duration}, das Maximum liegt bei {max}). Bitte kontaktiere uns, damit wir dich bei deinem Export unterstützen können.',
  'projectVideo.renderButton.tooLong.relative': 'Leider ist dein Video zu lang, um von uns gerendert werden zu können ({duration}, das Maximum liegt bei {max}). Bitte kontaktiere uns, damit wir dich bei deinem Export unterstützen können.',
  'projectVideo.renderedVideo.downloadSubtitles': '{format} Untertitel',
  'projectVideo.renderedVideo.lastExported': 'Exportiert {timeAgo}',
  'projectVideo.renderedVideo.pendingChanges': 'Ausstehende Änderungen',
  'projectVideo.renderedVideo.update': 'Video aktualisieren',
  'projectVideo.renderJobFailed.subtitle': 'Ein technisches Problem hat den Export gestoppt.',
  'projectVideo.renderJobFailed.title': 'Bei uns ist etwas schiefgelaufen',
  'quickActions.recordScreen.button': 'Bildschirm aufzeichnen',
  'quickActions.recordYourself.button': 'Sich selbst aufzeichnen',
  'reset.again': 'Du hast keine E-Mail erhalten? Erneut <link>Anfordern</link>.',
  'reset.form.password.placeholder': 'Neues Passwort',
  'reset.form.passwordConfirmation.placeholder': 'Neues Passwort wiederholen',
  'reset.headline': 'Willkommen zurück zu cofenster!',
  'reset.subline': 'Bitte gib ein neues Passwort ein, um dich bei cofenster einzuloggen.',
  'reset.title': 'Passwort zurücksetzen',
  'resetSent.headline': 'Bitte überprüfe deinen Posteingang.',
  'resetSent.subline': 'Wir haben dir einen Link zum Zurücksetzen des Passworts an deine E-Mail-Adresse geschickt.',
  'resetSent.title': 'E-Mail gesendet',
  'roleRequest.button.request': 'Berechtigungen anfordern',
  'roleRequest.button.requestSharingLinkPermissions': 'Mehr Rechte anfragen',
  'roleRequest.pending.text': 'Ausstehende Anfrage',
  'roleRequests.item.approve': 'Genehmigen',
  'roleRequests.item.content': '{userName} fordert ein Upgrade auf die Rolle {roleName} an',
  'roleRequests.item.deny': 'Ablehnen',
  'roleRequests.item.requester': 'Benutzer',
  'roleRequests.title': 'Folgende Mitglieder erwarten ein Upgrade ihrer Rolle',
  'sceneAsset.recordYourselfButton.image': 'Foto machen',
  'sceneAsset.recordYourselfButton.screen': 'Bildschirm aufnehmen',
  'sceneAsset.recordYourselfButton.video': 'Video aufnehmen',
  'sceneAsset.stock.image': 'Stockfoto hinzufügen',
  'sceneAsset.stock.video': 'Stockvideo hinzufügen',
  'SceneAudioOverlay.headline': 'Audio',
  sceneExcluded: 'Szene vom Rendern ausgeschlossen',
  'sceneImage.dropzone.text': 'Lege dein Bild hier ab oder durchsuche deinen Computer',
  'sceneImage.error': 'Ups. Bei der Verarbeitung deines Bildes ist ein Fehler aufgetreten.',
  'sceneImage.processing': 'Einen Moment, dein Bild ist gleich fertig!',
  'sceneImage.uploading': 'Dein Bild wird hochgeladen',
  'sceneSubtitle.bottomNavigation.nthSceneLabel': 'Szene #{nth}',
  'sceneSubtitle.bottomNavigation.playPause.durationTooltip': 'Gesamtdauer der Szene #{nth}',
  'sceneSubtitle.bottomNavigation.sceneWithoutAssetTooltip': 'Szene hat keinen Inhalt',
  'sceneSubtitle.bottomNavigation.selectNthSceneLabel': 'Wähle Szene #{nth}',
  'sceneSubtitle.createForm.createSubtitlesButton.text': 'Untertitel erstellen',
  'sceneSubtitle.createForm.description': 'Diese Szene hat keine Untertitel',
  'sceneSubtitle.createForm.formType': 'Methode',
  'sceneSubtitle.createForm.title': 'Untertitel erstellen',
  'sceneSubtitle.createForm.whatLanguageToCaption.title': 'Welche Sprache möchtest du untertiteln?',
  'sceneSubtitle.CreateSceneSubtitleTypeSelect.automaticTranslation.description': 'Wir untertiteln die Szene für dich',
  'sceneSubtitle.CreateSceneSubtitleTypeSelect.automaticTranslation.title': 'Automatische Untertitel',
  'sceneSubtitle.CreateSceneSubtitleTypeSelect.fromSRT.description': 'Importiere eine Datei (.srt)',
  'sceneSubtitle.CreateSceneSubtitleTypeSelect.fromSRT.title': 'Untertitel Datei hochladen',
  'sceneSubtitle.CreateSceneSubtitleTypeSelect.manualTranslation.description': 'Du schreibst eigene Untertitel',
  'sceneSubtitle.CreateSceneSubtitleTypeSelect.manualTranslation.title': 'Manuelle Untertitel',
  'sceneSubtitle.currentSubtitleHeader.originalLanguage': 'Originalsprache',
  'sceneSubtitle.currentSubtitleHeader.spokenLanguage': 'Gesprochene Sprache',
  'sceneSubtitle.currentSubtitleHeader.title': 'Untertitel der Szene',
  'sceneSubtitle.deleteSubtitleDialog.confirm': 'Untertitel löschen',
  'sceneSubtitle.deleteSubtitleDialog.content': 'Du löschst dauerhaft die Untertitel in {language} für diese Szene.',
  'sceneSubtitle.deleteSubtitleDialog.title': 'Untertitel löschen',
  'sceneSubtitle.deleteSubtitleLanguageDialog.confirm': 'Sprache löschen',
  'sceneSubtitle.deleteSubtitleLanguageDialog.content': 'Alle für dieses Projekt erstellten Untertitel in {language} werden für alle Szenen gelöscht.',
  'sceneSubtitle.deleteSubtitleLanguageDialog.title': 'Sprache löschen',
  'sceneSubtitle.dropzone.hintText': '.SRT Dateiformat',
  'sceneSubtitle.dropzone.selected.noNamePlaceholder': 'Die SRT-Datei ist bereit hochgeladen zu werden',
  'sceneSubtitle.dropzone.text': 'Zieh die Datei hier hin oder durchsuche deinen Computer',
  'sceneSubtitle.editor.node.contentInput.label': 'Inhalt des Untertitels #{index}',
  'sceneSubtitle.editor.node.delete.label': 'Lösche Untertitel #{index}',
  'sceneSubtitle.editor.node.endTimeInput.label': 'Endzeit des Untertitels #{index}',
  'sceneSubtitle.editor.node.excluded': 'Dieser Teil der Szene wurde geschnitten',
  'sceneSubtitle.editor.node.excludedPartially': 'Dieser Teil der Szene wurde teilweise geschnitten',
  'sceneSubtitle.editor.node.startTimeInput.label': 'Startzeit des Untertitels #{index}',
  'sceneSubtitle.emptySubtitles.createFirstNodeButton': 'Untertitelzeile hinzufügen',
  'sceneSubtitle.emptySubtitles.description': 'Füge eine Untertitelzeile hinzu und beginne mit dem Untertiteln.',
  'sceneSubtitle.emptySubtitles.title': 'Keine Untertitel',
  'sceneSubtitle.error.description': 'Es gab einen Fehler auf unserer Seite beim Erstellen der Untertitel für diese Szene. Wenn der Fehler ({error}) bestehen bleibt, kannst du die Untertitel stattdessen auch manuell erstellen.',
  'sceneSubtitle.error.primaryActionButton': 'Untertitelerstellung erneut versuchen',
  'sceneSubtitle.error.secondaryActionButton': 'Manuell erstellen',
  'sceneSubtitle.error.title': 'Untertitelerstellung gescheitert',
  'sceneSubtitle.forms.languageRequired': 'Sprache ist erforderlich',
  'sceneSubtitle.forms.otherSceneSubtitleIdRequired': 'Sprache aus anderen Szenenuntertiteln ist erforderlich',
  'sceneSubtitle.forms.srtContentRequired': 'Bitte lade eine SRT Datei hoch',
  'sceneSubtitle.languageSelectorMenu.addLanguageButton': 'Sprache hinzufügen',
  'sceneSubtitle.languageSelectorMenu.addLanguageButtonForAllScenes': 'Sprache hinzufügen',
  'sceneSubtitle.languageSelectorMenu.deleteSubtitle': 'Untertitel löschen',
  'sceneSubtitle.languageSelectorMenu.downloadSRT': 'SRT Untetitel herunterladen',
  'sceneSubtitle.languageSelectorMenu.downloadVTT': 'VTT Untertitel herunterladen',
  'sceneSubtitle.languageSelectorMenu.moreOptions': 'Mehr Optionen',
  'sceneSubtitle.languageSelectorMenu.scenesSubtitled': '{count, plural, one {# Szene mit Untertiteln} other {# Szenen mit Untertiteln}}',
  'sceneSubtitle.manageLanguage.deleteLanguage': 'Sprache löschen',
  'sceneSubtitle.manageLanguage.downloadSRT': 'SRT Untertitel herunterladen',
  'sceneSubtitle.manageLanguage.downloadVTT': 'VTT Untertitel herunterladen',
  'sceneSubtitle.manageLanguage.triggerButtonText': 'Mehr',
  'sceneSubtitle.nothingToTranscribe.buttonText': 'Untertitel erstellen',
  'sceneSubtitle.nothingToTranscribe.description': 'Du kannst Untertitel hinzufügen',
  'sceneSubtitle.nothingToTranscribe.title': 'Keine Sprache erkannt',
  'sceneSubtitle.pages.title': 'Untertitel',
  'sceneSubtitle.select.languages': 'Sprachen',
  'sceneSubtitle.select.usedInThisProjectTitle': 'Verwendet in diesem Projekt',
  'sceneSubtitle.slider.cutOutSection.tooltip': 'Dieser Abschnitt ist ausgeschnitten',
  'sceneSubtitle.slider.cutOutSection.tooltipCutOutButtonTooltip': 'Zum Schneiden gehen',
  'sceneSubtitle.styles.applyToAllScenes': 'Auf alle Szenen anwenden',
  'sceneSubtitle.styles.applyToAllScenes.successSnackbar': 'Erfolgreich auf alle Szenen angewendet',
  'sceneSubtitle.styles.positionBottom': 'Unten',
  'sceneSubtitle.styles.positionMiddle': 'Mitte',
  'sceneSubtitle.styles.positionTop': 'Oben',
  'sceneSubtitle.styles.menuButtonText': 'Stil',
  'sceneSubtitle.styles.suggested': 'Empfohlen',
  'sceneSubtitle.transcription.autoDetected': '(automatisch erkannt)',
  'sceneSubtitle.transcription.failed.description': 'Es gab einen Fehler auf unserer Seite beim Erstellen der Untertitel für diese Szene. Wenn der Fehler bestehen bleibt, kannst du die Untertitel stattdessen auch manuell erstellen.',
  'sceneSubtitle.transcription.failed.primaryAction.button.text': 'Erneut versuchen',
  'sceneSubtitle.transcription.failed.title': 'Untertitelerstellung gescheitert',
  'sceneSubtitle.transcription.ready': '(Transkript erstellt)',
  'sceneSubtitle.transcription.spokenLanguageLabel': 'Im Video gesprochene Sprache',
  'sceneSubtitle.transcription.waitingForTranscription': 'Szene wird transkribiert…',
  'sceneSubtitle.translateForm.applyToAllScenes': '{language} auf alle Szenen anwenden',
  'sceneSubtitle.translateForm.description': 'Übersetze diese Szene nach {language}',
  'sceneSubtitle.translateForm.fromOtherScene.description': 'Wir übersetzen für dich',
  'sceneSubtitle.translateForm.fromOtherScene.title': 'Automatisch übersetzen',
  'sceneSubtitle.translateForm.fromSRT.description': 'Du stellst die Übersetzung bereit',
  'sceneSubtitle.translateForm.fromSRT.title': 'SRT Datei hochladen',
  'sceneSubtitle.translateForm.manual.description': 'Schreibe eine eigene Übersetzung',
  'sceneSubtitle.translateForm.manual.title': 'Manuell erstellen',
  'sceneSubtitle.translateForm.submitButtonText': 'Übersetzen',
  'sceneSubtitle.translateForm.title': 'Untertitel übersetzen',
  'sceneSubtitle.translating.description': 'Wir übersetzen die Untertitel für dich',
  'sceneSubtitle.translating.title': 'Untertitel werden übersetzt',
  'sceneSubtitle.translationQualityHint.description': 'Überprüfe die originalen Untertitel vor dem Übersetzen für ein optimales Ergebnis',
  'sceneSubtitle.translationQualityHint.title': 'Tipp für perfekte Übersetzungen',
  'sceneSubtitle.waitingForTheAsset.message': 'Szene wird vorbereitet',
  'SceneTextsPanel.delete.error': 'Wir konnten deinen Text nicht löschen. Bitte versuche es später erneut.',
  'SceneTextsPanel.form.error.required': 'Bitte füll diesen Inhalt aus',
  'SceneTextsPanel.headline.content': 'Inhalt',
  'SceneTextsPanel.headline.style': 'Stil',
  'SceneTextsPanel.headline': 'Text',
  'SceneTextsPanel.save.error': 'Wir konnten deinen Text nicht speichern. Bitte versuche es später erneut.',
  'sceneVideo.dropzone.text': 'Lege dein Video hier ab oder durchsuche deinen Computer',
  'sceneVideo.error': 'Ups. Bei der Verarbeitung deines Video ist ein Fehler aufgetreten. Wahrscheinlich ist dein Video fehlerhaft.',
  'sceneVideo.processing': 'Einen Moment, dein Video ist gleich fertig!',
  'sceneVideo.uploading': 'Dein Video wird hochgeladen',
  'set.form.submit': 'Account erstellen',
  'set.subline': 'Bitte erstelle ein Passwort, um dich in deinem cofenster Konto anzumelden.',
  'set.title': 'Passworterstellung',
  'set.tnc': 'Indem du fortfährst und dein Konto erstellst, stimmst du den <tnc>Allgemeinen Geschäftsbedingungen</tnc> und der <data>Datenschutzerklärung</data> von cofenster zu.',
  'settingsAccount.account.headline': 'Unternehmen',
  'settingsAccount.accountForm.name.label': 'Name',
  'settingsAccount.accountForm.name.placeholder': 'Name',
  'settingsAccount.AccountUserCreate.headline': 'Neuen Benutzer einladen',
  'SettingsAccount.ConsentDropzone.error.encrypted': 'Offenbar ist deine PDF-Datei verschlüsselt, daher können wir sie nicht verarbeiten.',
  'SettingsAccount.ConsentDropzone.error': 'Beim Hochladen deines Dokuments ist ein Fehler aufgetreten.',
  'SettingsAccount.ConsentDropzone.hint': 'Unverschlüsseltes .pdf, max 10MB',
  'SettingsAccount.ConsentDropzone.hintText': 'Deine Einverständniserklärung wird hochgeladen',
  'SettingsAccount.ConsentDropzone.text': 'Lege deine PDF Datei hier ab oder durchsuche deinen Computer',
  'SettingsAccount.ConsentForm.collectingConsent': 'Lade die Einverständniserklärung deines Unternehmens im PDF-Format hoch. Du kannst dies zur Voraussetzung für die Teilnahme an Videoprojekten machen. Lies mehr über <link>Einverständniserklärung einholen</link>.',
  'SettingsAccount.ConsentForm.dialog.content': 'Bist du sicher, dass du die Einverständniserklärung löschen möchtest?',
  'SettingsAccount.ConsentForm.dialog.header': 'Einverständniserklärung löschen',
  'SettingsAccount.ConsentForm.requireConsent': 'Einverständnis der Teilnehmer erforderlich machen',
  'SettingsAccount.ConsentForm.uploadedAt': 'Hochgeladen am {uploadedAt}',
  'settingsAccount.consents.headline': 'Einverständniserklärung',
  'settingsAccount.consents.subheadline': 'Eingeholte Einverständnisse',
  'settingsAccount.headline': 'Unternehmen',
  'settingsAccount.members.headline': 'Mitglieder',
  'settingsAccount.passwordForm.error.invalidCredentials': 'Das aktuelle Passwort ist falsch.',
  'settingsAccount.passwordForm.newPassword.label': 'Neues Passwort',
  'settingsAccount.passwordForm.newPassword.placeholder': 'Neues Passwort',
  'settingsAccount.passwordForm.newPasswordRepeat.label': 'Neues Passwort wiederholen',
  'settingsAccount.passwordForm.newPasswordRepeat.placeholder': 'Neues Passwort wiederholen',
  'settingsAccount.passwordForm.oldPassword.label': 'Aktuelles Passwort',
  'settingsAccount.passwordForm.oldPassword.placeholder': 'Aktuelles Passwort',
  'settingsAccount.personal.headline': 'Persönliche Einstellungen',
  'settingsAccount.teams.headline': 'Teams',
  'settingsAccount.userForm.email.label': 'E-Mail',
  'settingsAccount.userForm.email.placeholder': 'E-Mail Adresse',
  'settingsAccount.userForm.error.emailExists': 'Diese E-Mail Adresse ist bereits vergeben.',
  'settingsAccount.userForm.firstname.label': 'Vorname',
  'settingsAccount.userForm.firstname.placeholder': 'Vorname',
  'settingsAccount.userForm.lastname.label': 'Nachname',
  'settingsAccount.userForm.lastname.placeholder': 'Nachname',
  'settingsConsent.headline': 'Einverständniserklärung',
  'settingsCookie.button': 'Cookie Einwilligung bearbeiten',
  'settingsCookie.headline': 'Cookie Einwilligung',
  'settingsCookie.text': 'Weitere Informationen zu den Daten, die wir zur Verbesserung unserer Produkte sammeln, findest du in unserer Datenschutzerklärung. Du kannst deine Einwilligung jederzeit ändern.',
  'settingsMembers.addUser': 'Benutzer hinzufügen',
  'settingsPersonal.headline.changePassword': 'Passwort ändern',
  'settingsPersonal.headline.details': 'Einzelheiten',
  'SharingLinkSettingsDialog.availableSubtitlesSection': 'Verfügbare Untertitel',
  'SharingLinkSettingsDialog.notExportedYet': 'This language is available after exporting the subtitled video.',
  'SharingLinkSettingsDialog.subtitleLanguagesLegend': 'Untertitelsprache im exportierten Video sichtbar',
  'SharingLinkSettingsDialog.successSnackbar': 'Freigabeeinstellungen erfolgreich gespeichert.',
  'SharingLinkSettingsDialog.title': 'Freigabeeinstellungen',
  'sharingOptions.copyEmbed': 'Einbettcode kopieren',
  'sharingOptions.email': 'Via Email teilen',
  'sharingOptions.facebook': 'Auf Facebook teilen',
  'sharingOptions.linkedin': 'Auf LinkedIn teilen',
  'signin.form.email.placeholder': 'E-Mail',
  'signin.form.error.invalidCredentials': 'Ungültige E-Mail oder ungültiges Passwort',
  'signin.form.forgot': 'Passwort vergessen?',
  'signin.form.password.placeholder': 'Passwort',
  'signin.form.submit': 'Einloggen',
  'signin.headline': 'Willkommen bei cofenster!',
  'signin.title': 'Anmelden',
  'SignInError.headline': 'Bei deiner Anmeldung ist ein Problem aufgetreten.',
  'SignInError.requestAccess': 'Um Hilfe bitten',
  'SignInError.subline': 'Sende uns eine Nachricht mit Hilfe der Chat-Bubble, wenn du Unterstützung benötigst.',
  'SignInError.title': 'Anmeldefehler',
  'snackbars.folderArchivedSnackbar.copy': 'Dein Ordner wurde in das Archiv verschoben.',
  'snackbars.folderDeletedSnackbar.copy': 'Dein Ordner wurde gelöscht.',
  'snackbars.folderUnarchivedSnackbar.copy': 'Dein Ordner wurde reaktiviert.',
  'snackbars.projectCreatedSnackbar.copy': '🎉 Projekt erstellt! Erzähl deine Geschichte mit Szenen.',
  'snackbars.projectDuplicatedSnackbar.copy': 'Dein Projekt wurde dupliziert.',
  'snackbars.projectFolderMoved.copy': 'Dein Ordner wurde nach „{teamName}“ verschoben.',
  'snackbars.projectsArchivedSnackbar.copy': '{count, plural, =0 {Nichts wurde} one {Dein Projekt wurde} other {Deine Projekte wurden}} archiviert.',
  'snackbars.projectsDeletedSnackbar.copy': '{count, plural, =0 {Nichts wurde} one {Dein Projekt wurde} other {Deine Projekte wurden}} gelöscht.',
  'snackbars.projectsMovedSnackbar.copy': '{count, plural, =0 {Nichts wurde} one {Dein Projekt wurde} other {Deine Projekte wurden}} verschoben.',
  'snackbars.projectsUnarchivedSnackbar.copy': '{count, plural, =0 {Nichts wurde} one {Dein Projekt wurde} other {Deine Projekte wurden}} reaktiviert.',
  'snackbars.ShowSceneSnackbar.title.interactive': 'Diese Szene ist ausgeblendet: <button>Hier klicken</button> um die Vorschau zu sehen.',
  'snackbars.ShowSceneSnackbar.title': 'Diese Szene ist ausgeblendet.',
  'snackbars.userDeactivatedSnackbar.copy': '😴 der Benutzer wurde erfolgreich deaktiviert',
  'snackbars.UserDeletedSnackbar.copy': 'Der Benutzer „{name}“ wurde gelöscht',
  'snackbars.userReactivatedSnackbar.copy': '🎉 der Benutzer wurde erfolgreich reaktiviert',
  'snackbars.UserReinvitedSnackbar.copy': '✉  Eine Einladung wurde an {email} verschickt',
  'snackbars.videoLink.copy': 'Videolink in die Zwischenablage kopiert',
  'snackbars.videoLink.embed': 'Einbettungscode in die Zwischenablage kopiert',
  'subNavigation.backToEditing': 'Zurück zur Bearbeitung',
  'subNavigation.branding.musics': 'Musik',
  'subNavigation.branding.themes': 'Themes',
  'subNavigation.design': 'Design',
  'subNavigation.edit': 'Editieren',
  'subNavigation.members': 'Mitglieder',
  'subNavigation.more': 'Mehr',
  'subNavigation.moreLanguages': '{count} weitere {count, plural, one {Sprache} other {Sprachen}}',
  'subNavigation.music': 'Musik',
  'subNavigation.settings.account': 'Unternehmen',
  'subNavigation.settings.consent': 'Einverständniserklärung',
  'subNavigation.settings.members': 'Mitglieder',
  'subNavigation.settings.notifications': 'Meine Benachrichtigungen',
  'subNavigation.settings.personal': 'Persönliche Einstellungen',
  'subNavigation.settings.teams': 'Teams',
  'subNavigation.settings': 'Einstellungen',
  'subNavigation.subtitles': 'Untertitel',
  'subNavigation.video': 'Video',
  'team.access.info': 'Die Verfügbarkeit des Teams kann später nicht mehr geändert werden.',
  'team.access': 'Zugang',
  'team.addMembers': 'Mitglieder hinzufügen',
  'team.addTeam.create': 'Team erstellen',
  'team.addTeam.decription': 'Ein Ort, an dem dein Team kollaborieren kann',
  'team.delete.info': 'Lösche dieses Team und seine Inhalte für immer. Verschiebe Videos die du behalten möchtest zuvor in ein anderes Team.',
  'team.delete': 'Team löschen',
  'team.details': 'Details',
  'team.error.nameLength': 'Der Name darf maximal 30 Zeichen lang sein',
  'team.error.nameRequired': 'Name ist erforderlich',
  'team.leave': 'Team verlassen',
  'team.member': 'Mitglied',
  'team.members.all': 'Alle',
  'team.members': 'Mitglieder',
  'team.name': 'Teamname',
  'team.owner': 'Eigentümer',
  'team.private.info': 'Nur für Mitglieder die du einlädst',
  'team.private': 'Privates Team',
  'team.public.info': 'Jeder kann etwas beitragen',
  'team.public': 'Öffentliches Team',
  'team.role.Admin.description': 'Den Account, Teams und Rollen von Mitgliedern verwalten.',
  'team.role.Admin.warning': 'Du kannst nicht die Rolle des einzigen Admins ändern.',
  'team.role.Admin': 'Admin',
  'team.role.Contributor.description': 'Projekte sehen. Szenen und Übersetzungen beitragen.',
  'team.role.Contributor': 'Mitwirkende*r',
  'team.role.Editor.description': 'Projekte verwalten. Projekte editieren und Teilnehmer einladen.',
  'team.role.Editor': 'Editor*in',
  'team.role.help': 'Erfahre mehr über Rollen',
  'team.role.Owner.description': 'Das Team leiten. Rollen im Team verwalten.',
  'team.role.Owner': 'Owner*in',
  'team.role.Publisher.description': 'Projekte verwalten. Editieren, Export und Teilen von Projekten.',
  'team.role.Publisher': 'Publisher*in',
  'team.role.Translator.description': 'Untertitel managen. Übersetzungen zu Projekten hinzufügen.',
  'team.role.Translator': 'Übersetzer*in',
  'team.role.Viewer': 'Betrachter*in',
  'team.save': 'Speichern',
  'team.settings': 'Einstellungen',
  'upsellBanner.actions.contact': 'Kontaktiere uns',
  'upsellBanner.actions.info': 'Mehr erfahren',
  'upsellBanner.CONSENT.content': 'Stelle sicher, dass Videos rechtlich konform bereitgestellt werden',
  'upsellBanner.CONSENT.title': 'Einverständniserklärungen automatisch einholen',
  'upsellBanner.CUSTOM_MUSICS.content': 'Erweitere deinen Plan für Zugang zu mehr Musikoptionen',
  'upsellBanner.CUSTOM_MUSICS.title': 'Nutze deine eigenen Soundtracks',
  'upsellBanner.MULTIPLE_TEAMS.content': 'Aktualisiere deinen Plan, um dein Konto besser zu organisieren',
  'upsellBanner.MULTIPLE_TEAMS.title': 'Erhalte Zugriff auf mehrere Teams',
  'upsellBanner.SCENE_OVERLAYS.content': 'Erstelle fesselndere Videos mit einer weiteren Videoebene, indem du deinen Plan erweiterst.',
  'upsellBanner.SCENE_OVERLAYS.title': 'Füge eine weitere Videoebene zu Szenen hinzu',
  'upsellBanner.SPECIAL_FORMATS.content': 'Erweitere deine verfügbaren Designoptionen',
  'upsellBanner.SPECIAL_FORMATS.title': 'Erstelle Videos speziell für Social media',
  'upsellBanner.STOCK_LIBRARY.content': 'Aktualisiere deinen Plan, um Videos mit Stockvideos und Stockfotos zu erstellen',
  'upsellBanner.STOCK_LIBRARY.title': 'Erhalte Zugriff auf unsere Stock Bibliothek',
  'upsellBanner.VIDEO_ANALYTICS.title': 'Erweitere deinen Plan, um Einblicke darüber zu bekommen, wie oft und wie lange das Video angesehen wird',
  'userRole.role': 'Rolle',
  'videoAnalytics.enableLink': 'Jeder mit dem Link kann das Video sehen',
  'videoAnalytics.enableSharing.actionButton': 'Link aktivieren',
  'videoAnalytics.enableSharing.description': 'Erhalte Daten über das Zuschauerverhalten wenn du den Link teilst',
  'videoAnalytics.enableSharing.title': 'Teile einen Link zu deinem Video',
  'videoAnalytics.share': 'Teilen',
  'videoAnalytics.sharingLinkDisabledTooltip': 'Öffentlich Teilen steht Admins und Publisher*innen zur Verfügung',
  'videoAnalytics.sharingLinkSettingsButtonLabel': 'Einstellungen für Freigabelink',
  'videoAnalytics.totalViews.description': 'Alle Ansichten des Videos',
  'videoAnalytics.totalViews.title': 'Gesamte Ansichten',
  'videoAnalytics.uniqueViews.description': 'Individuelle Ansichten des Videos',
  'videoAnalytics.uniqueViews.title': 'Individuelle Zuschauer',
  'videoAnalytics.watchTime.description': 'Wiedergabedauer pro Videoansicht',
  'videoAnalytics.watchTime.title': 'Durchschn. Wiedergabedauer',
};
