import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { type SceneAsset, useScenes } from '../../contexts/scenes/useScenes';
import { canPlayVideoAsset } from '../../helpers/sceneAssets/canPlayVideoAsset';
import { useGotoEditorScene } from '../../hooks/navigation/useGotoEditorScene';
import type { ProjectEditorRouteParams } from '../../routes';

import { getSceneVideoDuration } from '../../helpers/sceneAssets/getSceneVideoDuration';
import {
  isColorSceneAsset,
  isImageSceneAsset,
  isMainSceneAsset,
  isVideoSceneAsset,
} from '../../helpers/sceneAssets/is';
import { useIntroOutro } from './useIntroOutro';

const getHasAsset = (mainSceneAsset?: SceneAsset) => {
  if (!mainSceneAsset) return false;
  if (isVideoSceneAsset(mainSceneAsset))
    return !!mainSceneAsset.asset?.videoAsset?.videoUrl && !!mainSceneAsset.asset?.videoAsset.duration;
  if (isImageSceneAsset(mainSceneAsset)) return !!mainSceneAsset.asset?.imageAsset?.imageUrl;
  if (isColorSceneAsset(mainSceneAsset)) return !!mainSceneAsset.asset?.colorAsset?.color;
  return false;
};

const getIsAssetReady = (mainSceneAsset?: SceneAsset) => {
  if (!mainSceneAsset) return false;
  if (isVideoSceneAsset(mainSceneAsset)) return mainSceneAsset.asset?.videoAsset?.status === 'Ready';
  if (isImageSceneAsset(mainSceneAsset)) return mainSceneAsset.asset?.imageAsset?.status === 'Ready';
  if (isColorSceneAsset(mainSceneAsset)) return true;
  return false;
};

const getCanDisplayAsset = (mainSceneAsset?: SceneAsset) => {
  if (!mainSceneAsset) return false;

  if (isColorSceneAsset(mainSceneAsset)) return true;

  if (isVideoSceneAsset(mainSceneAsset)) {
    if (!mainSceneAsset.asset?.videoAsset) return false;
    return canPlayVideoAsset(mainSceneAsset.asset.videoAsset);
  }

  if (isImageSceneAsset(mainSceneAsset)) {
    return mainSceneAsset.asset?.imageAsset?.status === 'Ready';
  }

  return false;
};

const getDuration = (mainSceneAsset?: SceneAsset) => {
  if (!mainSceneAsset) return 0;
  if (isVideoSceneAsset(mainSceneAsset))
    return mainSceneAsset.asset ? (getSceneVideoDuration(mainSceneAsset.asset) ?? 0) : 0;
  if (isImageSceneAsset(mainSceneAsset))
    return mainSceneAsset.asset?.imageAsset?.status === 'Ready' ? (mainSceneAsset.asset?.duration ?? 0) : 0;
  if (isColorSceneAsset(mainSceneAsset)) return mainSceneAsset.asset?.duration ?? 0;
  return 0;
};

export type SceneTimings = {
  id: string;
  start: number;
  end: number;
  hasAsset: boolean;
  isAssetReady: boolean;
  canDisplayAsset: boolean;
};

export const useSceneTimings = ({ withExcludedScenes }: { withExcludedScenes: boolean }) => {
  const { scenes } = useScenes();

  const { intro, outro, isUploadedAndEnabled } = useIntroOutro();

  const introDuration = intro?.videoAsset?.duration;
  const outroDuration = outro?.videoAsset?.duration;

  const withIntro = isUploadedAndEnabled('Intro') && typeof introDuration === 'number';
  const withOutro = isUploadedAndEnabled('Outro') && typeof outroDuration === 'number';

  return useMemo(() => {
    if (!scenes) return [];
    let accumulatedTime = 0;

    const sceneTimings: SceneTimings[] = scenes
      .filter((scene) => (withExcludedScenes ? true : !scene.excluded))
      .map((scene) => {
        const mainSceneAsset = scene.sceneAssets.find(isMainSceneAsset);
        const duration = getDuration(mainSceneAsset);
        const hasAsset = getHasAsset(mainSceneAsset);
        const isAssetReady = getIsAssetReady(mainSceneAsset);
        const canDisplayAsset = getCanDisplayAsset(mainSceneAsset);

        const timing = {
          id: scene.id,
          start: accumulatedTime,
          end: accumulatedTime + duration,
          hasAsset,
          isAssetReady,
          canDisplayAsset,
        };
        accumulatedTime += duration;

        return timing;
      });

    const sceneTimingsWithIntroOutro: SceneTimings[] = [];

    if (withIntro) {
      sceneTimingsWithIntroOutro.push({
        id: 'intro',
        start: 0,
        end: introDuration,
        hasAsset: true,
        isAssetReady: true,
        canDisplayAsset: true,
      });
    }

    for (const sceneTiming of sceneTimings) {
      const offset = withIntro ? introDuration : 0;
      sceneTimingsWithIntroOutro.push({
        ...sceneTiming,
        start: sceneTiming.start + offset,
        end: sceneTiming.end + offset,
      });
    }

    if (withOutro) {
      const last = sceneTimingsWithIntroOutro[sceneTimingsWithIntroOutro.length - 1];
      const end = last?.end ?? 0;

      sceneTimingsWithIntroOutro.push({
        id: 'outro',
        start: end,
        end: end + outroDuration,
        hasAsset: true,
        isAssetReady: true,
        canDisplayAsset: true,
      });
    }

    return sceneTimingsWithIntroOutro;
  }, [scenes, withIntro, withOutro, introDuration, outroDuration, withExcludedScenes]);
};

export const usePlayerScenes = (preventSceneUpdates: boolean) => {
  const { projectId, sceneId } = useParams() as ProjectEditorRouteParams;
  const sceneTimings = useSceneTimings({ withExcludedScenes: false });
  const gotoEditorScene = useGotoEditorScene(projectId);
  const { scenes } = useScenes();

  const setCurrentScene = useCallback(
    (sceneId: string) => {
      const isExcluded = scenes.find((scene) => scene.id === sceneId)?.excluded;
      if (isExcluded) return;

      if (preventSceneUpdates) return;
      gotoEditorScene(sceneId);
    },
    [gotoEditorScene, preventSceneUpdates, scenes]
  );

  const sceneTimingsSortedByTime = useMemo(
    () => sceneTimings.filter((scene) => scene.hasAsset).sort((a, b) => a.start - b.start),
    [sceneTimings]
  );

  const currentSceneIndex = sceneTimingsSortedByTime.findIndex((scene) => scene.id === sceneId);
  const canGoNext = currentSceneIndex < sceneTimingsSortedByTime.length - 1;
  const canGoPrev = currentSceneIndex > 0;

  const gotoNextScene = useCallback(() => {
    if (currentSceneIndex === sceneTimingsSortedByTime.length - 1) return;

    const nextSceneIndex = currentSceneIndex + 1;
    const nextSceneTiming = sceneTimingsSortedByTime[nextSceneIndex];

    if (nextSceneTiming) {
      setCurrentScene(nextSceneTiming.id);
      return nextSceneTiming.start;
    }
  }, [currentSceneIndex, sceneTimingsSortedByTime, setCurrentScene]);

  const gotoPreviousScene = useCallback(() => {
    if (currentSceneIndex === 0) return;

    const previousSceneIndex = currentSceneIndex - 1;
    const previousSceneTiming = sceneTimingsSortedByTime[previousSceneIndex];

    if (previousSceneTiming) {
      setCurrentScene(previousSceneTiming.id);
      return previousSceneTiming.start;
    }
  }, [currentSceneIndex, sceneTimingsSortedByTime, setCurrentScene]);

  return useMemo(
    () => ({
      setCurrentScene,
      sceneTimings,
      gotoNextScene: canGoNext ? gotoNextScene : undefined,
      gotoPreviousScene: canGoPrev ? gotoPreviousScene : undefined,
    }),
    [setCurrentScene, sceneTimings, canGoNext, gotoNextScene, canGoPrev, gotoPreviousScene]
  );
};
