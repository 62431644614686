import { useField, useFormikContext } from 'formik';
import {
  type CSSProperties,
  type ChangeEventHandler,
  type FC,
  type FocusEventHandler,
  type HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  type CSSObject,
  TextareaAutosize,
  type TypographyVariants,
  preventForwardProps,
  styled,
  useI18n,
} from '@cofenster/web-components';

// 1. An additional 24px needs to be included in the width because the width of the text
//    is determined after rendering it in the hidden span. Without the extra width, the input
//    will scroll to the right, and the text will be cut off on the left for a moment.
// 2. Decrease the additional space on the right side of the input caused by the extra width (point 1).
const StyledInput = styled(
  'input',
  preventForwardProps(['hasText', 'isVariableWidth', 'variant'])
)<{ variant: TypographyVariants; hasText: boolean | undefined; isVariableWidth?: boolean | undefined }>(
  ({ theme, variant, hasText, isVariableWidth }) => ({
    display: 'inline-block',
    width: `calc(var(--variable-width, 100%) + ${theme.spacing(isVariableWidth && hasText ? 3 : 2)})`, // 1
    border: 0,
    borderRadius: theme.shape.borderRadius,

    font: 'inherit',
    ...(theme.typography[variant] as CSSObject),
    color: theme.palette.brand.carbon,
    transition: 'background-color 250ms',

    padding: theme.spacing(1),
    margin: isVariableWidth ? theme.spacing(0, -1, 0, 0) : theme.spacing(0, -1), // 2

    '&:hover:not(:focus)': {
      backgroundColor: theme.palette.brand.grey50,
    },

    '&:focus': {
      outline: `1px solid ${theme.palette.brand.blue}`,
    },
  })
);

const StyledTextarea = styled(StyledInput.withComponent(TextareaAutosize))(() => ({
  resize: 'none',
}));

type TextInputProps = {
  multiline?: false;
};

type TextAreaProps = {
  multiline: true;
  maxRows?: number;
  minRows?: number;
};

type Props = HTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
  name: string;
  label: string;
  placeholder?: string;
  variant: TypographyVariants;
  track?: FocusEventHandler;
  disabled?: boolean;
  maxLength?: number;
  autoWidthAdjust?: boolean;
} & (TextInputProps | TextAreaProps);

export const SmartFormField: FC<Props> = ({
  name,
  label,
  placeholder,
  variant,
  track,
  maxLength,
  autoWidthAdjust,
  multiline = false,
  ...rest
}) => {
  const [width, setWidth] = useState(0);
  const { dirty, submitForm } = useFormikContext();
  const [{ value = '', onBlur, onChange }] = useField(name);
  const { translatable } = useI18n();
  const FieldComponent = multiline ? StyledTextarea : StyledInput;

  const handleBlur: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(
    async (event) => {
      onBlur(event);

      if (dirty) {
        await submitForm();
        track?.(event);
      }
    },
    [onBlur, dirty, submitForm, track]
  );

  const onChangeWithLimit: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      const newLength = event.target.value?.length ?? 0;
      if (typeof maxLength !== 'undefined' && newLength > maxLength) return;
      onChange(event);
    },
    [onChange, maxLength]
  );

  return (
    <>
      <FieldComponent
        {...rest}
        style={
          autoWidthAdjust
            ? ({
                '--variable-width': `${width}px`,
              } as CSSProperties)
            : undefined
        }
        name={name}
        value={value}
        onChange={onChangeWithLimit}
        onBlur={handleBlur}
        variant={variant}
        aria-label={translatable(label)}
        placeholder={translatable(placeholder)}
        autoComplete="off"
        hasText={!!value}
        isVariableWidth={autoWidthAdjust}
      />
      <InputTextWidthListener name={name} setWidth={setWidth} variant={variant} />
    </>
  );
};

const WidthListenerHiddenSpan = styled(
  'span',
  preventForwardProps(['variant'])
)<{ variant: TypographyVariants }>(({ theme, variant }) => ({
  visibility: 'hidden',
  position: 'absolute',
  wordBreak: 'break-word',
  ...(theme.typography[variant] as CSSObject),
}));

const InputTextWidthListener: FC<{
  setWidth: (width: number) => void;
  variant: TypographyVariants;
  name: string;
}> = ({ setWidth, variant, name }) => {
  const [{ value }] = useField(name);

  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries[0]) return;
      const { width } = entries[0].contentRect;
      setWidth(width);
    });
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [setWidth]);

  return (
    <WidthListenerHiddenSpan variant={variant} ref={ref}>
      {value}
    </WidthListenerHiddenSpan>
  );
};
