import { styled } from '@mui/material';
import { type ChangeEventHandler, type FC, type FocusEventHandler, useCallback, useState } from 'react';

import { useI18n } from '../../../services/i18n';
import { IconButton } from '../../controls/Button/IconButton';
import { TextField } from '../../controls/TextField';
import { GridContainer, GridItem } from '../../layout/Grid';

export type DurationInputProps = {
  value: number;
  onChange: (value: number) => void;
  step?: number;
  clickStep?: number;
  min?: number;
  max?: number;
  decimals?: number;
  unit?: string;
  disabled?: boolean;
};

const StyledTextField = styled(TextField)(() => ({
  width: 90,
  paddingBottom: 0,

  input: {
    textAlign: 'center',

    '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },
}));

const normalizeProps = ({
  decimals: decimalsProp = 1,
  step: stepProp,
  clickStep: clickStepProp,
  min: minProp,
}: Pick<DurationInputProps, 'decimals' | 'step' | 'clickStep' | 'min'>) => {
  // Ensure the `decimals` property is not negative
  const decimals = Math.max(0, decimalsProp);
  // Define the minimum step based on the `decimals` value
  const minStep = 1 / 10 ** decimals;
  // Define the actual step based on the given step prop and the minimum step
  const step = Math.max(minStep, stepProp ?? minStep);
  const clickStep = Math.max(minStep, clickStepProp ?? 1);
  // Define the actual minimum value based on the min prop and the minimum step
  const min = minProp ?? minStep;

  return { decimals, step, clickStep, min };
};

export const DurationInput: FC<DurationInputProps> = ({
  value = 0,
  onChange,
  step: stepProp,
  clickStep: clickStepProp,
  min: minProp,
  max = 99,
  decimals: decimalsProp = 1,
  unit = '',
  disabled = false,
}) => {
  const { decimals, min, step, clickStep } = normalizeProps({
    decimals: decimalsProp,
    min: minProp,
    step: stepProp,
    clickStep: clickStepProp,
  });

  // Math utils
  const round = useCallback((value: number) => Math.round(value * 10 ** decimals) / 10 ** decimals, [decimals]);
  const clamp = useCallback((value: number) => Math.min(max, Math.max(min, value)), [min, max]);
  const normalize = useCallback((value: number) => clamp(round(value)), [clamp, round]);
  const normalizedValue = normalize(value);

  // Value-related
  const [inputValue, setInputValue] = useState(value.toString());
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setInputValue(event.target.value),
    []
  );
  const setChangedValue = useCallback(
    (value: number) => {
      const normalizedValue = normalize(value);
      setInputValue(normalizedValue.toString());
      onChange(normalizedValue);
    },
    [onChange, normalize]
  );
  const handleIncrement = useCallback(
    () => setChangedValue(Number.isInteger(normalizedValue) ? normalizedValue + clickStep : Math.ceil(normalizedValue)),
    [normalizedValue, setChangedValue, clickStep]
  );
  const handleDecrement = useCallback(
    () =>
      setChangedValue(Number.isInteger(normalizedValue) ? normalizedValue - clickStep : Math.floor(normalizedValue)),
    [normalizedValue, setChangedValue, clickStep]
  );

  // Focus related
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setIsFocused(false);
      const value = Number.parseFloat(event.target.value);
      if (!Number.isNaN(value)) setChangedValue(value);
    },
    [setChangedValue]
  );

  // Display related
  const displayValue = `${normalizedValue.toFixed(decimals).toLocaleString()}${unit}`;
  const { translate } = useI18n();
  const numericProps = isFocused
    ? {
        type: 'number',
        inputMode: 'numeric' as const,
        min,
        max,
        step,
      }
    : undefined;

  return (
    <GridContainer spacing={1.5} justifyContent="center" alignItems="center">
      <GridItem>
        <IconButton
          icon="MinusCircleIcon"
          iconSize="l"
          onClick={handleDecrement}
          disabled={disabled || +inputValue === min}
          label="i18n.global.duration.decrease"
        />
      </GridItem>
      <GridItem>
        <StyledTextField
          value={isFocused ? inputValue : displayValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          inputProps={{
            'aria-label': translate('global.duration.label'),
          }}
          {...numericProps}
        />
      </GridItem>
      <GridItem>
        <IconButton
          icon="PlusCircleIcon"
          iconSize="l"
          onClick={handleIncrement}
          disabled={disabled || +inputValue === max}
          label="i18n.global.duration.increase"
        />
      </GridItem>
    </GridContainer>
  );
};
