import { type FC, useCallback, useMemo } from 'react';

import {
  BlankButton,
  Icon,
  ThumbnailBar,
  ThumbnailContainer,
  ThumbnailImage,
  ThumbnailOverlayContainer,
  Typography,
  styled,
  useGoto,
} from '@cofenster/web-components';

import { useCreateProjectFromProjectTemplate } from '../../api/hooks/projectTemplate/useCreateProjectFromProjectTemplate';
import type { ProjectTemplate } from '../../api/hooks/projectTemplate/useProjectTemplates';
import { useDialogs } from '../../contexts/dialogs';
import { useUser } from '../../contexts/user/useUser';
import { CofensterProjectTemplateIndicator } from './CofensterProjectTemplateIndicator';
import { ProjectTemplateMenu } from './ProjectTemplateMenu';

const StyledBlankButton = styled(BlankButton)(({ theme }) => ({
  '&:focus-visible': theme.mixins.focusRing,
  width: '100%',
  borderRadius: theme.shape['borderRadius-l'],
}));

const VIDEO_FORMAT_TO_LABEL = {
  Horizontal: 'i18n.common.horizontal',
  Vertical: 'i18n.common.vertical',
  Square: 'i18n.common.square',
  SocialMedia: 'i18n.common.socialMedia',
};

const FormatInformation = styled('div')(({ theme }) => ({
  background: theme.palette.brand.white,
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
}));

const useOnPreviewProjectTemplate = (
  projectTemplate: ProjectTemplate,
  folderId?: string,
  createProject?: (input: { name: string; projectTemplateId: string; projectFolderId: string }) => Promise<
    { id: string } | undefined
  >
) => {
  const goto = useGoto();
  const { openDialog } = useDialogs();
  const { user } = useUser();
  const [defaultCreateProject] = useCreateProject();

  return useCallback(() => {
    openDialog('ProjectTemplatePreviewDialog', {
      projectTemplate,
      personalFolderId: user?.rootProjectFolder?.id ?? '',
      enforcedProjectId: folderId,
      goto,
      createProject: createProject ?? defaultCreateProject,
    });
  }, [openDialog, projectTemplate, user, folderId, goto, createProject, defaultCreateProject]);
};

const useCreateProject = () => {
  const [createProjectFromProjectTemplate, metadata] = useCreateProjectFromProjectTemplate();
  return useMemo(() => {
    return [
      async (input: { name: string; projectTemplateId: string; projectFolderId: string }) => {
        const result = await createProjectFromProjectTemplate(input.projectTemplateId, {
          projectFolderId: input.projectFolderId,
          name: input.name,
        });
        return result?.data?.createProjectFromProjectTemplate;
      },
      metadata,
    ] as const;
  }, [createProjectFromProjectTemplate, metadata]);
};

export const ProjectTemplateTile: FC<{
  projectTemplate: ProjectTemplate;
  hideMenu?: boolean;
  folderId?: string;
  createProject?: (input: { name: string; projectTemplateId: string; projectFolderId: string }) => Promise<
    { id: string } | undefined
  >;
}> = ({ projectTemplate, folderId, createProject, hideMenu = false }) => {
  const onPreviewProjectTemplate = useOnPreviewProjectTemplate(projectTemplate, folderId, createProject);

  return (
    <>
      <StyledBlankButton onClick={onPreviewProjectTemplate}>
        <ThumbnailContainer background={projectTemplate.thumbnailUrl}>
          <ThumbnailImage
            imageAsset={{ imageUrl: projectTemplate.thumbnailUrl ?? '/assets/images/missing-thumbnail.jpg' }}
            title={projectTemplate.project.name}
            objectFit="cover"
          />
          <CofensterProjectTemplateIndicator />
          <ThumbnailOverlayContainer anchor="BottomLeft">
            <FormatInformation>
              <Typography variant="s">{VIDEO_FORMAT_TO_LABEL[projectTemplate.project.videoFormat]}</Typography>
              <Icon size="s" type="MovieIcon" />
              <Typography variant="s">{projectTemplate.project.completedSceneCount}</Typography>
            </FormatInformation>
          </ThumbnailOverlayContainer>
        </ThumbnailContainer>
      </StyledBlankButton>
      <ThumbnailBar
        title={projectTemplate.project.name}
        menu={
          !hideMenu &&
          !projectTemplate.byCofenster && (
            <ProjectTemplateMenu
              projectTemplate={projectTemplate}
              onPreviewProjectTemplate={onPreviewProjectTemplate}
            />
          )
        }
      />
    </>
  );
};
