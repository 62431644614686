import type { FC } from 'react';

import {
  PopoverMenuDivider,
  PopoverMenuItem,
  PopoverMenuTriggerIcon,
  withPopoverMenu,
} from '@cofenster/web-components';

import type { MediaLibraryItem } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { AccountPermissionRestriction } from '../../contexts/user/AccountPermissionRestriction';
import { useDeleteMediaLibraryItemWithConfirm } from '../../hooks/mediaLibrary/useDeleteMediaLibraryItemWithConfirm';
import { useGotoMediaLibraryItem } from './useGotoMediaLibraryItem';
import { useRenameMediaLibraryItem } from './useRenameMediaLibraryItem';

export const MediaLibraryItemPopoverMenuIcon: FC<{ mediaLibraryItem: MediaLibraryItem }> = ({ mediaLibraryItem }) => {
  const gotoMediaLibraryItem = useGotoMediaLibraryItem(mediaLibraryItem.id);
  const [deleteMediaLibraryItemWithConfirm] = useDeleteMediaLibraryItemWithConfirm(mediaLibraryItem.id);
  const renameMediaLibraryItem = useRenameMediaLibraryItem(mediaLibraryItem);

  const PopoverMenuIcon = withPopoverMenu(PopoverMenuTriggerIcon, {
    children: (
      <>
        <PopoverMenuItem
          icon="EyeIcon"
          onClick={gotoMediaLibraryItem}
          data-testid="media-library-item-popover-view-button"
        >
          i18n.mediaLibrary.item.view
        </PopoverMenuItem>
        <AccountPermissionRestriction has="MediaLibraryUpdate">
          <PopoverMenuItem
            icon="TextBoxIcon"
            onClick={renameMediaLibraryItem}
            data-testid="media-library-item-popover-rename-button"
          >
            i18n.common.rename
          </PopoverMenuItem>
        </AccountPermissionRestriction>

        <AccountPermissionRestriction has="MediaLibraryDelete">
          <PopoverMenuDivider />
          <PopoverMenuItem
            icon="TrashIcon"
            onClick={() => deleteMediaLibraryItemWithConfirm()}
            color="negative"
            data-testid="media-library-item-popover-delete-button"
          >
            i18n.global.delete
          </PopoverMenuItem>
        </AccountPermissionRestriction>
      </>
    ),
    transformOriginX: 'left',
    anchorOriginX: 'left',
    offsetY: 4,
  });

  return <PopoverMenuIcon data-testid="media-library-item-more-options" />;
};
