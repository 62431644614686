import { useCallback, useMemo } from 'react';

import { type DeleteMediaLibraryItemMutationVariables, useDeleteMediaLibraryItemMutation } from '../../generated';

export const useDeleteMediaLibraryItem = () => {
  const [deleteMediaLibraryItem, metadata] = useDeleteMediaLibraryItemMutation({
    refetchQueries: ['MediaLibraryItems'],
  });

  const mutation = useCallback(
    (id: DeleteMediaLibraryItemMutationVariables['id']) => deleteMediaLibraryItem({ variables: { id } }),
    [deleteMediaLibraryItem]
  );

  return useMemo(() => [mutation, metadata] as const, [mutation, metadata]);
};
