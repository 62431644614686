import { type FC, useCallback, useMemo } from 'react';

import { Dialog, DialogContent } from '@cofenster/web-components';
import type { MediaLibraryItem } from '../../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { MediaLibraryItemsGridView } from '../../../pages/MediaLibrary/MediaLibraryItemsGridView';
import { MediaLibrarySearchAndPagination } from '../../../pages/MediaLibrary/MediaLibrarySearchAndPagination';
import { MediaLibrarySearchWithPaginationContext } from '../../../pages/MediaLibrary/MediaLibrarySearchWithPaginationContext';

export type PickMediaLibraryItemDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  assetType?: 'IMAGE' | 'VIDEO';
  onMediaLibraryItemPicked: (mediaLibraryItem: MediaLibraryItem) => Promise<unknown>;
};

export const PickMediaLibraryItemDialog: FC<PickMediaLibraryItemDialogProps> = ({
  isOpen,
  closeDialog,
  assetType,
  onMediaLibraryItemPicked,
}) => {
  const useOnAction = useMemo(
    () => (item: MediaLibraryItem) => {
      return async () => {
        await onMediaLibraryItemPicked(item);
        closeDialog();
      };
    },
    [onMediaLibraryItemPicked, closeDialog]
  );

  const mediaLibraryResult = useCallback(
    ({ mediaLibraryItems }: { mediaLibraryItems: MediaLibraryItem[] }) => (
      <MediaLibraryItemsGridView mediaLibraryItems={mediaLibraryItems} useOnAction={useOnAction} compact />
    ),
    [useOnAction]
  );

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="i18n.common.mediaLibrary" size="l">
      <DialogContent>
        <MediaLibrarySearchWithPaginationContext
          defaultValues={{
            type: assetType,
          }}
          itemsPerPage={9}
        >
          <MediaLibrarySearchAndPagination Results={mediaLibraryResult} />
        </MediaLibrarySearchWithPaginationContext>
      </DialogContent>
    </Dialog>
  );
};
