import { type FieldConverter, type FieldConvertersMap, useUrlParameters } from '@cofenster/web-components';
import {
  type Context,
  type FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

type FilterAndPagination<F extends {}> = {
  readonly filter: F;
  setFilter(key: keyof F, value: F[keyof F]): void;

  readonly page: number;
  setPage(page: number): void;
};

const useContextOrThrow = <T,>(context: Context<T | undefined>) => {
  const value = useContext(context);

  if (value === undefined) {
    throw new Error('Context value is undefined');
  }

  return value;
};

const pageDefaultValue = { page: 1 };
const pageUrlSerializer = { page: { serialize: String, deserialize: Number } };
const pageKeys = ['page' as const];

const useSearchFilters = <T extends {}>(
  itemsPerPage: number,
  initialState: T,
  urlSerializer: FieldConvertersMap<T>,
  keys: (keyof T)[]
) => {
  const [pageState, setPageState] = useUrlParameters(pageDefaultValue, pageUrlSerializer, pageKeys);
  const [state, setState] = useUrlParameters(initialState, urlSerializer, keys);

  const setPage = useCallback(
    (page: number) => {
      setPageState({ page });
    },
    [setPageState]
  );

  const setFilter = useCallback(
    (key: keyof T, value: T[keyof T]) => {
      if (!keys.includes(key)) return console.warn(`Field ${String(key)} is not controlled by this context`);
      setState({ ...state, [key]: value });
    },
    [state, setState, keys]
  );

  const filter = useMemo(
    () => ({
      ...state,
      limit: itemsPerPage,
      offset: Math.max(pageState.page - 1, 0) * itemsPerPage,
    }),
    [pageState.page, state, itemsPerPage]
  );

  const { page } = pageState;
  // Whenever the search parameters have been changed, reinitialize the page to
  // the first one.
  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    if (state) setPage(1);
  }, [state]);

  return useMemo(() => ({ page, setPage, filter, setFilter }), [page, setPage, filter, setFilter]);
};

type Field<F, K extends keyof F> = {
  serialize?: FieldConverter<F[K]>['serialize'];
  deserialize?: FieldConverter<F[K]>['deserialize'];
  defaultValue?: F[K];
};

/**
 * Creates a search filter and pagination context.
 *
 * @template F - The shape of the filter fields, represented by a generic object.
 *
 * @param {Object.<keyof F, Field<F, keyof F>>} fields - An object where each key
 * represents a filterable field and its associated metadata (e.g., default value).
 * If a key is not included, the corresponding field will not be tracked or managed
 * by this context.
 *
 * @returns An object containing the context, Provider, and a custom hook useFilterAndPagination
 */
export const createSearchFilterAndPaginationContext = <F extends {}>(
  fields: Required<{ [key in keyof F]: Field<F, key> }>
) => {
  const keys = Object.keys(fields) as (keyof F)[];
  const globalControlledDefaultValues = Object.entries(fields).reduce<F>((acc, [key, value]) => {
    const typedKey = key as keyof F;
    const typedValue = value as Field<F, keyof F>;
    if (typedValue.defaultValue) acc[typedKey] = typedValue.defaultValue;
    return acc;
  }, {} as F);

  const context = createContext<FilterAndPagination<F> | undefined>(undefined);

  const Provider: FC<PropsWithChildren<{ defaultValues?: F; itemsPerPage: number }>> = ({
    defaultValues,
    itemsPerPage,
    children,
  }) => {
    const contextValue = useSearchFilters(itemsPerPage, defaultValues ?? globalControlledDefaultValues, fields, keys);
    return <context.Provider value={contextValue}>{children}</context.Provider>;
  };

  const useFilterAndPagination = () => useContextOrThrow(context);

  return { context, Provider, useFilterAndPagination };
};
