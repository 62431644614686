import { useCallback } from 'react';
import type { MediaLibraryItem } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { useUpdateMediaLibraryItem } from '../../api/hooks/mediaLibraryItem/useUpdateMediaLibraryItem';
import { useRenameDialog } from '../../hooks/useRenameDialog';
import { useWebManagerTracking } from '../../hooks/useWebManagerTracking';

export const useRenameMediaLibraryItem = (mediaLibraryItem: MediaLibraryItem) => {
  const [updateMediaLibraryItem] = useUpdateMediaLibraryItem();
  const { trackEvent } = useWebManagerTracking();
  const renameAndTrack = useCallback(
    async ({ name }: { name: string }) => {
      await updateMediaLibraryItem(mediaLibraryItem.id, { title: name });
      trackEvent({
        event: 'mediaLibraryItemRenamed',
        details: {
          mediaLibraryItemId: mediaLibraryItem.id,
          title: name,
          oldTitle: mediaLibraryItem.title,
        },
      });
    },
    [mediaLibraryItem, updateMediaLibraryItem, trackEvent]
  );

  const openRenameDialog = useRenameDialog({
    initialValues: { name: mediaLibraryItem.title },
    onSubmit: renameAndTrack,
    title: 'i18n.mediaLibrary.rename.title',
    subtitle: 'i18n.mediaLibrary.rename.subtitle',
    submitButtonContent: 'i18n.mediaLibrary.rename.renameButton',
  });

  return useCallback(() => openRenameDialog(), [openRenameDialog]);
};
