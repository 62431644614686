import type { FC } from 'react';

import { Select, SelectOption, type SelectProps, Translatable } from '@cofenster/web-components';

import type { MediaLibraryOrderBy } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { useMediaLibraryFilterAndPagination } from './MediaLibrarySearchWithPaginationContext';

const options: MediaLibraryOrderBy[] = ['ALPHABETICAL', 'MOST_POPULAR', 'LAST_USED'];

const translations: Record<MediaLibraryOrderBy, string> = {
  ALPHABETICAL: 'i18n.mediaLibrary.orderBy.ALPHABETICAL',
  MOST_POPULAR: 'i18n.mediaLibrary.orderBy.MOST_POPULAR',
  LAST_USED: 'i18n.mediaLibrary.orderBy.LAST_USED',
};

export const MediaLibraryOrderBySelect: FC<Omit<SelectProps, 'label' | 'name' | 'value'>> = ({
  id = 'orderBy',
  ...rest
}) => {
  const { filter, setFilter } = useMediaLibraryFilterAndPagination();

  return (
    <Select
      id={id}
      name="orderBy"
      label="i18n.common.orderBy"
      defaultValue="ALPHABETICAL"
      value={filter.orderBy}
      renderValue={(value) => {
        const typedValue = value as MediaLibraryOrderBy;
        return <Translatable>{translations[typedValue]}</Translatable>;
      }}
      onChange={(event) => setFilter('orderBy', event.target.value as MediaLibraryOrderBy)}
      pb={0}
      {...rest}
    >
      {options.map((option) => (
        <SelectOption key={option} value={option}>
          {translations[option]}
        </SelectOption>
      ))}
    </Select>
  );
};
