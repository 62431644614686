import type { FC } from 'react';

import {
  EmptyState,
  GridContainer,
  GridItem,
  LoadingSpinner,
  ResultPagination,
  SearchResults,
  Typography,
  styled,
} from '@cofenster/web-components';

import { type MediaLibraryItem, useMediaLibraryItems } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { MediaLibraryOrderBySelect } from './MediaLibraryOrderBySelect';
import { MediaLibrarySearchField } from './MediaLibrarySearchField';
import { useMediaLibraryFilterAndPagination } from './MediaLibrarySearchWithPaginationContext';

const FlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const TypographyWhiteSpaceNoWrap = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
}));

export const MediaLibrarySearchAndPagination: FC<{
  Results: FC<{ mediaLibraryItems: MediaLibraryItem[] }>;
}> = ({ Results }) => {
  const { filter, page, setPage } = useMediaLibraryFilterAndPagination();

  const { paginatedMediaLibraryItems, loading, error } = useMediaLibraryItems(filter);

  return (
    <GridContainer>
      <GridItem md={9} xs={12}>
        <FlexContainer>
          <TypographyWhiteSpaceNoWrap
            i18nParams={{ count: paginatedMediaLibraryItems?.total ?? 0 }}
            variant="h4"
            component="h2"
          >
            {filter.type === 'IMAGE'
              ? 'i18n.mediaLibrary.search.total.image'
              : filter.type === 'VIDEO'
                ? 'i18n.mediaLibrary.search.total.video'
                : 'i18n.mediaLibrary.search.total'}
          </TypographyWhiteSpaceNoWrap>
          <MediaLibrarySearchField />
        </FlexContainer>
      </GridItem>
      <GridItem md={3} xs={12}>
        <MediaLibraryOrderBySelect />
      </GridItem>
      <GridItem xs={12}>
        <SearchResults id="media-library" count={paginatedMediaLibraryItems?.total ?? 0} search={filter.search ?? ''}>
          {loading ? (
            <LoadingSpinner />
          ) : error ? (
            <EmptyState
              iconType="CloudErrorIcon"
              title="i18n.mediaLibrary.search.error.title"
              description="i18n.mediaLibrary.search.error.description"
            />
          ) : (
            <Results mediaLibraryItems={paginatedMediaLibraryItems?.items ?? []} />
          )}
        </SearchResults>
      </GridItem>
      {paginatedMediaLibraryItems && (
        <GridItem xs={12} display="flex" justifyContent="center">
          <ResultPagination
            total={paginatedMediaLibraryItems.total}
            limit={paginatedMediaLibraryItems.limit || paginatedMediaLibraryItems.total}
            page={page}
            onChange={setPage}
          />
        </GridItem>
      )}
    </GridContainer>
  );
};
