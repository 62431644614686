import { useCallback } from 'react';
import { useDeleteMediaLibraryItem } from '../../api/hooks/mediaLibraryItem/useDeleteMediaLibraryItem';
import { useConfirmDialog } from '../useConfirmDialog';
import { useWebManagerTracking } from '../useWebManagerTracking';

export const useDeleteMediaLibraryItemWithConfirm = (mediaLibraryItemId: string) => {
  const [deleteMediaLibraryItem, metadata] = useDeleteMediaLibraryItem();
  const { trackEvent } = useWebManagerTracking();

  const deleteAndTrack = useCallback(async () => {
    await deleteMediaLibraryItem(mediaLibraryItemId);
    trackEvent({
      event: 'mediaLibraryItemDeleted',
      details: { mediaLibraryItemId },
    });
  }, [mediaLibraryItemId, deleteMediaLibraryItem, trackEvent]);

  const deleteMediaLibraryItemWithConfirm = useConfirmDialog({
    onConfirm: deleteAndTrack,
    title: 'i18n.mediaLibrary.item.confirmDelete.title',
    content: 'i18n.mediaLibrary.item.confirmDelete.content',
    confirm: 'i18n.global.delete',
  });

  return [deleteMediaLibraryItemWithConfirm, metadata] as const;
};
