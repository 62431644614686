import { LAYER_INDEXES } from '@cofenster/constants';
import { Button, type ButtonProps, Icon } from '@cofenster/web-components';
import { type FC, type MouseEventHandler, useCallback } from 'react';
import type { MediaLibraryItem } from '../../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { useCreateSceneAsset } from '../../../api/hooks/sceneAsset/useCreateSceneAsset';
import { useDialogs } from '../../../contexts/dialogs';

export const PickFromMediaLibraryButton: FC<
  {
    assetType: 'IMAGE' | 'VIDEO';
    sceneId: string;
  } & Omit<ButtonProps, 'onClick' | 'children' | 'startIcon'>
> = ({ assetType, sceneId, ...rest }) => {
  const { openDialog } = useDialogs();

  const createSceneAsset = useCreateSceneAsset();
  const onMediaLibraryItemPicked = useCallback(
    async (item: MediaLibraryItem) => {
      const isVideo = !!item.videoAsset?.id;
      await createSceneAsset({
        assetType: isVideo ? 'Video' : 'Image',
        sceneId,
        assetId: item?.videoAsset?.id ?? (item?.imageAsset?.id as string),
        layerIndex: LAYER_INDEXES.main,
        startTime: 0,
      });
    },
    [createSceneAsset, sceneId]
  );

  const onClick: MouseEventHandler = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog('PickMediaLibraryItemDialog', {
        assetType,
        onMediaLibraryItemPicked,
      });
    },
    [openDialog, assetType, onMediaLibraryItemPicked]
  );

  return (
    <Button variant="secondary" {...rest} onClick={onClick} startIcon={<Icon type="MediaLibraryIcon" size="s" />}>
      i18n.mediaLibrary.pickAssetButton
    </Button>
  );
};
