import type { FC } from 'react';

import {
  BlankButton,
  Chip,
  EmptyState,
  GridContainer,
  GridItem,
  ThumbnailBar,
  ThumbnailContainer,
  ThumbnailImage,
  ThumbnailOverlayContainer,
  ThumbnailVideo,
  ThumbnailVideoInteraction,
  formatDuration,
  styled,
} from '@cofenster/web-components';
import type { MediaLibraryItem } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { MediaLibraryItemAssetStatus } from './MediaLibraryItemAssetStatus';
import { MediaLibraryItemPopoverMenuIcon } from './MediaLibraryItemPopoverMenuIcon';
import { useMediaLibraryFilterAndPagination } from './MediaLibrarySearchWithPaginationContext';
import { useGotoMediaLibraryItem } from './useGotoMediaLibraryItem';
import { useIsFilterFilled } from './useIsFilterFilled';

const useDefaultOnAction = (mediaLibraryItem: MediaLibraryItem) => useGotoMediaLibraryItem(mediaLibraryItem.id);

const StyledBlankButton = styled(BlankButton)(({ theme }) => ({
  '&:focus-visible': theme.mixins.focusRing,
  width: '100%',
  borderRadius: theme.shape['borderRadius-l'],
}));

const MediaLibraryTileButton: FC<{
  mediaLibraryItem: MediaLibraryItem;
  useOnAction: (mediaLibraryItem: MediaLibraryItem) => () => unknown;
}> = ({ mediaLibraryItem, useOnAction }) => {
  const onAction = useOnAction(mediaLibraryItem);
  return (
    <ThumbnailVideoInteraction>
      {({ ref, onInteractionEnter, onInteractionLeave }) => (
        <StyledBlankButton
          onClick={onAction}
          onMouseEnter={onInteractionEnter}
          onMouseLeave={onInteractionLeave}
          onFocus={onInteractionEnter}
          onBlur={onInteractionLeave}
        >
          <ThumbnailContainer background="checkerboard">
            {mediaLibraryItem.videoAsset && (
              <>
                <ThumbnailVideo ref={ref} videoAsset={mediaLibraryItem.videoAsset} />
                {mediaLibraryItem.videoAsset.status !== 'Ready' && (
                  <ThumbnailOverlayContainer anchor="TopRight">
                    <MediaLibraryItemAssetStatus status={mediaLibraryItem.videoAsset.status} />
                  </ThumbnailOverlayContainer>
                )}
                {mediaLibraryItem.videoAsset.duration && (
                  <ThumbnailOverlayContainer anchor="BottomRight">
                    <Chip color="dark">{formatDuration(mediaLibraryItem.videoAsset.duration)}</Chip>
                  </ThumbnailOverlayContainer>
                )}
              </>
            )}
            {mediaLibraryItem.imageAsset && (
              <>
                <ThumbnailImage imageAsset={mediaLibraryItem.imageAsset} />
                {mediaLibraryItem.imageAsset.status !== 'Ready' && (
                  <ThumbnailOverlayContainer anchor="TopRight">
                    <MediaLibraryItemAssetStatus status={mediaLibraryItem.imageAsset.status} />
                  </ThumbnailOverlayContainer>
                )}
              </>
            )}
          </ThumbnailContainer>
        </StyledBlankButton>
      )}
    </ThumbnailVideoInteraction>
  );
};

export const MediaLibraryItemsGridView: FC<{
  mediaLibraryItems: MediaLibraryItem[];
  useOnAction?: (mediaLibraryItem: MediaLibraryItem) => () => unknown;
  compact?: boolean;
}> = ({ mediaLibraryItems, useOnAction = useDefaultOnAction, compact = false }) => {
  const { filter } = useMediaLibraryFilterAndPagination();
  const isFilterFilled = useIsFilterFilled();

  if (mediaLibraryItems.length === 0) {
    return isFilterFilled ? (
      <EmptyState
        iconType="XCircleIcon"
        title="i18n.mediaLibrary.emptySearchResults.title"
        titleProps={{ i18nParams: { search: filter.search } }}
        description="i18n.mediaLibrary.emptySearchResults.description"
      />
    ) : (
      <EmptyState
        iconType="MediaLibraryIcon"
        title="i18n.mediaLibrary.emptyState.title"
        description="i18n.mediaLibrary.emptyState.description"
      />
    );
  }

  return (
    <GridContainer>
      {mediaLibraryItems.map((mediaLibraryItem) => (
        <GridItem xs={12} sm={6} md={4} key={mediaLibraryItem.id}>
          <MediaLibraryTileButton mediaLibraryItem={mediaLibraryItem} useOnAction={useOnAction} />
          {!compact && (
            <ThumbnailBar
              title={mediaLibraryItem.title}
              menu={<MediaLibraryItemPopoverMenuIcon mediaLibraryItem={mediaLibraryItem} />}
            />
          )}
        </GridItem>
      ))}
    </GridContainer>
  );
};
