import { type FC, type MouseEventHandler, useMemo, useState } from 'react';

import { Button, DurationInput, Icon, styled, usePopover, withPopover } from '@cofenster/web-components';

import { useEditorPlayer } from '../../../../contexts/editorPlayer/useEditorPlayer';

const ColContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const PopoverContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

const useFormattedRate = (rate = 1) => {
  return `${rate.toFixed(1)} x`;
};

const PlaybackRateButton: FC<{
  playbackRate: number;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}> = ({ playbackRate, onClick, disabled }) => (
  <Button variant="tertiary" disabled={disabled} startIcon={<Icon type="ClockIcon" size="s" />} onClick={onClick}>
    {useFormattedRate(playbackRate)}
  </Button>
);

const PlaybackRatePopover: FC<{ playbackRate: number; onChange: (value: number) => void }> = ({
  playbackRate,
  onChange,
}) => {
  const [uncommitedPlaybackRate, setUncommitedPlaybackRate] = useState(playbackRate);
  const { closePopover } = usePopover();

  return (
    <PopoverContainer>
      <ColContainer>
        <DurationInput
          value={uncommitedPlaybackRate}
          min={0.2}
          max={5}
          step={0.1}
          clickStep={0.5}
          unit=" x"
          onChange={setUncommitedPlaybackRate}
        />
        <Button
          variant="primary"
          disabled={playbackRate === uncommitedPlaybackRate}
          onClick={() => {
            onChange(uncommitedPlaybackRate);
            closePopover();
          }}
        >
          Set playback rate
        </Button>
      </ColContainer>
    </PopoverContainer>
  );
};

export const SceneVideoPlaybackRateButton: FC<{
  playbackRate: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}> = ({ playbackRate, onChange, disabled }) => {
  const { pause } = useEditorPlayer();
  const PlaybackRateButtonWithPopover = useMemo(
    () =>
      withPopover(PlaybackRateButton, {
        anchorOriginX: 'center',
        anchorOriginY: 'top',
        transformOriginX: 'center',
        transformOriginY: 'bottom',
        offsetY: -20,
        children: <PlaybackRatePopover playbackRate={playbackRate} onChange={onChange} />,
      }),
    [playbackRate, onChange]
  );

  return <PlaybackRateButtonWithPopover playbackRate={playbackRate} disabled={disabled} onClick={pause} />;
};
