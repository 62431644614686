import { useCallback, useMemo } from 'react';

import { type UpdateMediaLibraryItemMutationVariables, useUpdateMediaLibraryItemMutation } from '../../generated';

export const useUpdateMediaLibraryItem = () => {
  const [updateMediaLibraryItem, metadata] = useUpdateMediaLibraryItemMutation({
    refetchQueries: ['MediaLibraryItem', 'MediaLibraryItems'],
  });

  const mutation = useCallback(
    async (
      id: UpdateMediaLibraryItemMutationVariables['id'],
      input: UpdateMediaLibraryItemMutationVariables['input']
    ) => updateMediaLibraryItem({ variables: { id, input } }),
    [updateMediaLibraryItem]
  );

  return useMemo(() => [mutation, metadata] as const, [mutation, metadata]);
};
