import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../../routes';

export const useGotoMediaLibraryItem = (mediaLibraryItemId: string, from = routes.brandingMediaLibrary) => {
  const goto = useGoto();

  return useCallback(
    () => goto(routes.brandingMediaLibraryItem, { mediaLibraryItemId }, { state: { from } }),
    [goto, mediaLibraryItemId, from]
  );
};
